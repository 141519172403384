import React from "react";
import "./style.scss";
import sketchTop from "../../assets/images/footer-top-texturev2.png";
import sketchBottom from "../../assets/images/footer-bottom-texturev2.png";
import HeadingWithTag from "../../components/headings/HeadingWithTag";
import tag from "../../assets/images/cards/tag.png";
import book from "../../assets/images/cards/book.png";
import person from "../../assets/images/cards/person.png";
import rise from "../../assets/images/cards/rise.png";
import recycle from "../../assets/images/cards/recycle.png";
import settings from "../../assets/images/cards/settings.png";

const Index = () => {
  const cards = [
    {
      icon: tag,
      name: "<div>Exceptional Service</div>",
      description:
        "We place a high value on service quality. If you aren't satisfied, we'll make immediate improvements.",
    },
    {
      icon: book,
      name: "<div>Comprehensive<br /> NDA</div>",
      description:
        "Your confidentiality is our top priority! We safeguard the exclusivity of your project with stringent non-disclosure agreements.",
    },
    {
      icon: rise,
      name: "<div>Expandable<br /> Operations</div>",
      description:
        "Allow your website to speak for itself. With our unique and compelling designs, you may capture the attention of global businesses.",
    },
    {
      icon: recycle,
      name: "<div>Rapid<br /> Turnaround Time</div>",
      description:
        "Need quick help? We have everything covered! Your chosen project manager is here to meet your specific requirements.",
    },
    {
      icon: person,
      name: "<div>Dedicated<br /> Project Manager</div>",
      description:
        "Require immediate support? We've got it covered! Your designated project manager is here to address your specific needs.",
    },
    {
      icon: settings,
      name: "<div>Agency-Focused<br /> Procedures</div>",
      description:
        "We focus solely on your individual requirements. Do you have to meet stringent requirements? Our goal is to surpass your expectations through our efforts.",
    },
  ];

  // chicken lion cat
  return (
    <div className="other__communities__main">
      <img className="sketh__images" src={sketchBottom} alt="sketch top" />
      <div className="other__communities__sub__section">
        <div className="flex__section container">
          <div className="d-flex justify-content-center">
            <HeadingWithTag black text="Our commitments" />
          </div>
          <div className="cards__section">
            {cards?.map((v, i) => {
              return (
                <div className="card__custom" key={i}>
                  <div className="heading__section">
                    <div>
                      <img alt={"icon"} src={v?.icon} />
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: v?.name }}
                      className="card__heading"
                    ></div>
                  </div>
                  <div className="card__description">{v?.description}</div>
                </div>
              );
            })}
          </div>
        </div>
        <img className="sketh__images" src={sketchTop} alt="sketch top" />
      </div>
    </div>
  );
};

export default Index;
