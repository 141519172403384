import React, { useState } from 'react';
import './style.scss';
import { Footer, MouseRing, Navbar } from '../../components';
import { PortfolioCardSection, PortfolioHero } from '../../layout';
import { CgClose } from "react-icons/cg";

const Index = () => {

    const [fullImage, setFullImage] = useState(null)

    return (
        <>
            <MouseRing />
            <div className='root'>
                <Navbar />
                <PortfolioHero />
                <PortfolioCardSection setFullImage={setFullImage} />
                <Footer />
                <div className='home__main'>
                </div>
                {fullImage === null ? "" : <div className="full__image">
                    <button onClick={() => setFullImage(null)} className='close__btn'>
                        <CgClose className='close__icon__2' size={40} />
                    </button>
                    <img src={fullImage} alt="full portfolio" />
                </div>}
            </div>

        </>
    )
}

export default Index