import pic7 from './12.webp';
import pic2 from './22.webp';
import pic3 from './28.webp';
import pic4 from './30.webp';
import pic5 from './32.webp';
import pic6 from './35.webp';
import pic19 from './37.webp';
import pic8 from './40.webp';
import pic9 from './45.webp';
import pic10 from './5-1.webp';
import pic11 from './5.webp';
import pic12 from './51.webp';
import pic13 from './52.webp';
import pic14 from './BS - Bob Schiro - Web 01.webp';
import pic15 from './DV - David Jump 4 Joy - Web 01.webp';
import pic16 from './GET - Good Earth Tours - Web 01.webp';
import pic17 from './ITC - Informia Tech Chains - Web 01 copy.webp';
import pic18 from './TA - Tanisha - Web01.webp';
import pic1 from "./Teammate Children's Stories.webp";

export let websitePictures = [
  pic1,
  pic2,
  pic3,
  pic4,
  pic5,
  pic6,
  pic7,
  pic8,
  pic9,
  pic10,
  pic11,
  pic12,
  pic13,
  pic14,
  pic15,
  pic16,
  pic17,
  pic18,
  pic19,
];
