import React from 'react'
import './style.scss'
import ReviewCarousel from './components/Carousel'
import lion from '../../..//assets/images/lion.png'
import chicken from '../../..//assets/images/chicken.png'
import dog from '../../..//assets/images/dog.png'
import cat from '../../..//assets/images/cat.png'
import deer from '../../..//assets/images/deer.png'

const Index = () => {
  const reviews = [
    {
      name: "THE FOUNDER",
      detail: "CEO",
      description: "““We believe in curating bespoke and unforgettable digital experiences.”",
      imgUrl:lion
    },
    {
      name: "THE DESIGNERS",
      detail: "34+ WEB DESIGNERS",
      description: "“We settle for nothing but your brand’s domination. Watch us transform your ideas into digital masterpieces.”",
      imgUrl:deer
    },
    {
      name: "THE CREATIVES",
      detail: "28+ BRAND MANAGERS",
      description: "“Competition grows, but so can your brand’s potential, reach, and success. Let our tailor-made design and branding solutions prove how it's done.”",
      imgUrl:cat
    },
    {
      name: "DEVELOPERS",
      detail: "38+ WEB DEVELOPERS",
      description: "“Focus on your business plan, and leave the technical jargon and complex combinations of 0s and 1s to us.”",
      imgUrl:dog
    },
    {
      name: "THE MARKETERS",
      detail: "29+ DIGITAL MARKETERS",
      description: "“Turn their heads and grab their attention. Our recipe for marketing guarantees that the digital spotlight never leaves your side.”",
      imgUrl:chicken
    },
  ]

  return (
    <div className='about__reviews__main'>
      <div>
        <ReviewCarousel reviews={reviews} />
      </div>
    </div>
  )
}

export default Index
