import React from 'react'
import './style.scss'

const Index = ({ text, side, dir, layer, fontFamily, fontSize }) => {

  let dirClass = ""
  if (side === "left") {
    if (dir === "ltr") {
      dirClass = "left__side__ltr"
    } else {
      dirClass = "left__side__rtl"
    }
  } else {
    if (dir === "ltr") {
      dirClass = "right__side__ltr"
    } else {
      dirClass = "right__side__rtl"
    }
  }

  const style ={}
  if(layer){
    style.zIndex = layer
  }
  if(fontFamily){
    style.fontFamily = fontFamily
  }
  if(fontSize){
    style.fontSize = fontSize
  }

  return (
    <div style={style} className={`lines__main ${dirClass}`}>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
      <span>{text}</span>
    </div>
  )
}

export default Index