import React from 'react'
import './style.scss'
import texture from '../../../assets/images/heading__texture.png'


const Index = ({ title }) => {
  return (
    <div className='portfolio__hero__main container'>
      <div className="text__section">
        {title ?
          <h2 className='d-flex'>
            <img src={texture} alt='texture' className='right__texture' />
            <img src={texture} alt='texture2' className='left__texture' />
            {title.split('').map((text, index) => (
              <div data-aos="flip-right" key={index}>{text}</div>
            ))}
          </h2>
          : <h2 className='d-flex'>
            <img src={texture} alt='texture' className='right__texture' />
            <img src={texture} alt='texture2' className='left__texture' />
            <div data-aos="flip-right">P</div>
            <div data-aos="flip-right">O</div>
            <div data-aos="flip-right">R</div>
            <div data-aos="flip-right">T</div>
            <div data-aos="flip-right">F</div>
            <div data-aos="flip-right">O</div>
            <div data-aos="flip-right">L</div>
            <div data-aos="flip-right">I</div>
            <div data-aos="flip-right">O</div>
          </h2>}
        <p data-aos="fade-up" className='about__description'>
          PROJECTS WE'VE COMPLETED
        </p>
      </div>
    </div>
  )
}

export default Index