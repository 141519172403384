import React from 'react';
import './style.scss';
import { Footer, MouseRing, Navbar } from '../../components';
import {  ServiceHero, WebDesignDetails } from '../../layout';
import webdesignVideo from '../../assets/video/design-branding-vid.mp4';


const Index = () => {
    return (
        <div className='root'>
            <MouseRing />
            <Navbar />
            <ServiceHero videoUrl={webdesignVideo} />
            <WebDesignDetails />
            <Footer />
            <div className='home__main'>
            </div>
        </div>
    )
}

export default Index