import React, { useState } from "react";
import "./style.scss";
import { CTAButton } from "../../../components";
import sketchTop from "../../../assets/images/footer-top-texturev2.png";
import sketchBottom from "../../../assets/images/footer-bottom-texturev2.png";
import map from "../../../assets/images/contact/map.png";
import { BsFacebook, BsLinkedin } from "react-icons/bs";
import {
  AiOutlineTwitter,
  AiFillYoutube,
  AiFillGooglePlusSquare,
} from "react-icons/ai";
import { FaSquareInstagram } from "react-icons/fa6";
import { BsFillTelephoneFill } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { FaMapMarkerAlt } from "react-icons/fa";

const Index = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    "Info",
    "web design",
    "digital marketing",
    "design & branding",
    "web development",
  ];

  const changeTab = (tab) => setActiveTab(tab);

  const social = [
    {
      Icon: BsFacebook,
      link: "https://www.facebook.com",
    },
    {
      Icon: AiOutlineTwitter,
      link: "https://twitter.com",
    },
    {
      Icon: FaSquareInstagram,
      link: "https://www.instagram.com",
    },
    {
      Icon: BsLinkedin,
      link: "https://www.linkedin.com",
    },
    {
      Icon: AiFillYoutube,
      link: "https://www.facebook.com",
    },
    {
      Icon: AiFillGooglePlusSquare,
      link: "https://www.facebook.com",
    },
  ];

  // chicken lion cat
  return (
    <div className="contact__form__main">
      <img className="sketh__images" src={sketchBottom} alt="sketch top" />
      <div className="about__sub__section">
        <div className="tab__section">
          {tabs?.map((v, i) => {
            return (
              <CTAButton
                onClick={() => changeTab(i)}
                text={v}
                key={i}
                customClass={`padd__less ${activeTab === i ? "active" : ""} `}
              />
            );
          })}
        </div>
        {activeTab === 0 ? (
          <div className="flex__section container">
            <div className="images__area">
              <img alt="map" src={map} />
            </div>
            <div className="about__area">
              <p>
                Our team dedicates lengthy hours and utmost effort in shaping
                your desired outcomes. What are you waiting for then?
              </p>
              <p>
                Give us a call or drop your queries, and give us just 24 hours
                to get back to you.
              </p>
              <div className="links__section">
                <div>
                  <a href="tel:+44 730 1494637">
                    <BsFillTelephoneFill />
                    &nbsp;&nbsp; +44 730 1494637
                  </a>
                </div>
                <div>
                  <a href="tel:+1 (289) 295-1684">
                    <BsFillTelephoneFill />
                    &nbsp;&nbsp; +1 (289) 295-1684
                  </a>
                </div>
                <div>
                  <a href="mailto:hello@apexverse.co">
                    <HiOutlineMail />
                    &nbsp;&nbsp; hello@apexverse.co
                  </a>
                </div>
                <div>
                  <a href="mailto:hello@apxeverse.co">
                    <FaMapMarkerAlt />
                    &nbsp;&nbsp; 513 CHEAM SUTTON LONDON ROAD
                  </a>
                </div>
                <div>
                  <a href="mailto:hello@apxeverse.co">
                    <FaMapMarkerAlt />
                    &nbsp;&nbsp; 4717 Washington Blvd Suite B
                    Halethrope, MD 21227
                  </a>
                </div>
                <div className="d-flex gap-2 icons__section mt-3 px-3">
                  {social?.map(({ Icon, link }, i) => {
                    return (
                      <div key={i}>
                        <Icon className="social__icon" size={24} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex__section container">
            <div className="form__area">
              <h3>{tabs[activeTab]}</h3>
              <div className="d-flex justify-content-between">
                <div className="input__section width50">
                  <input className="w-100" placeholder="Name*" />
                </div>
                <div className="input__section width50">
                  <input className="w-100" placeholder="Email*" />
                </div>
              </div>
              <div className="input__section mt-3">
                <input className="w-100" placeholder="Phone*" />
              </div>
              <div className="input__section mt-3">
                <input className="w-100" placeholder="Message*" />
              </div>
              <div className="mt-3">
                <p>
                  I have read, understand and accept the privacy policy and
                  terms and conditions Apexverse
                </p>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <CTAButton text={"Send"} />
              </div>
            </div>
            <div className="text__area">
              {activeTab === 1 ? (
                <div>
                  <p>
                    Are you looking for an out-of-the-box and eye-catching look
                    for your website?
                  </p>
                  <p>
                    Look no further than the tried and tested website design
                    solutions offered by Apexverse. We are available 24/7 to
                    chat about your project and get this journey started.
                  </p>
                </div>
              ) : activeTab === 2 ? (
                <div>
                  <p>
                    Are you ready to launch your digital marketing campaign to a
                    roaring success?
                  </p>
                  <p>
                    We have the right tools and techniques up our sleeve to
                    elevate your online presence in the market. Get in touch
                    with us today for a successful tomorrow.
                  </p>
                </div>
              ) : activeTab === 3 ? (
                <div>
                  <p>
                    Are you ready to stand out in the market and gain your
                    customer’s trust?
                  </p>
                  <p>
                    Our creative team works tirelessly to tailor design and
                    branding solutions to your business’s success and
                    recognition. Share your expectations and let us breathe a
                    new life into your dream project.
                  </p>
                </div>
              ) : (
                <div>
                  <p>
                    Are you looking for coding experts, programming specialists,
                    and web wizards to develop a high-ranking and fully
                    functional website for your brand?
                  </p>
                  <p>
                    Congratulations! You arrived at the most suitable
                    destination. Just share a project brief with our team and
                    give us a day to design an exclusive website development
                    plan for you.
                  </p>
                </div>
              )}
              <div>
                <a href="mailto:hello@apexverse.co">
                  <HiOutlineMail />
                  &nbsp;&nbsp; hello@apexverse.co
                </a>
              </div>
            </div>
          </div>
        )}
        <img className="sketh__images" src={sketchTop} alt="sketch top" />
      </div>
    </div>
  );
};

export default Index;
