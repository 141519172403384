import React, { useContext } from 'react'
import './style.scss'
import { AuthContext } from '../../context'

const CTABtn = ({ text, onClick, bgPink, customClass }) => {

    const { setCursor } = useContext(AuthContext)

    const mouseOut = () => setCursor('')
    const mouseHover = () => setCursor('fill')

    return (
        <button onMouseOver={mouseHover} onMouseOut={mouseOut} className={`cta__btn ${bgPink ? "bgPink" : ""} ${customClass ?? ""}`} onClick={onClick}>
            {text}
        </button>
    )
}

export default CTABtn
