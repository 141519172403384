import React from 'react'
import './style.scss'
import PortfolioCarousel from './components/Carousel'

const Index = () => {
    return (
        <div className='portfolio__main'>
            <h2 className='portfolio__heading'>
                <span>Digital</span> MasterPieces
            </h2>
            <h5 className='portfolio__description'>
                Beautiful and Interactive Web Designs
            </h5>
            <div data-aos="fade-down">
                <PortfolioCarousel />
            </div>
        </div>
    )
}

export default Index
