import React, { useContext, useEffect, useRef } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import './styles.scss';

// import required modules
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import { AuthContext } from '../../../context';
import { CTAButton } from '../../../components';
import { websitePictures } from '../../../assets/images/portfolio/Website Portfolio/websitePortfolio';
import nextArrow from '../../../assets/images/svg/next_arrow.svg';
import prevarrow from '../../../assets/images/svg/prevarrow.svg';


export default function PortfolioCarousel() {
  const { setCursor } = useContext(AuthContext);

  const swipper = useRef();
  const mouseHover = (type) => setCursor(type);
  const mouseOut = () => setCursor('');


  const handleSlideTo = (index) => {
    if (swipper.current && swipper.current.swiper) {
      swipper.current.swiper.slideTo(index);
    }
  };

  useEffect(() => {
    handleSlideTo(1);
  }, []);
  return (
    <>
      <div
        className="slider__main__div"
        onMouseOver={() => mouseHover('hidden')}
        onMouseOut={mouseOut}
      >
        <Swiper
          effect={'coverflow'}
          ref={swipper}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={3}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          loop
          pagination={false}
          active={2}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="portfolio__swipper"
          navigation={{
            nextEl: '.custom-next-btn',
            prevEl: '.custom-prev-btn',
          }}
        >
          {websitePictures?.map((v, i) => {
            return (
              <SwiperSlide key={i}>
                <div className="upper__div" />
                <img
                  src={v}
                  alt={`${i}`}
                  loading="lazy"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="d-flex justify-content-center align-items-center gap-4 mb-4 mt-3">
        <div>
          <button
            onMouseOut={mouseOut}
            onMouseOver={() => mouseHover('fill')}
            className="custom-prev-btn cutsom__btn"
          >
            <img
              src={prevarrow}
              alt="prevarr"
            />
          </button>
        </div>
        <div>
          <a href="/portfolio">
            <CTAButton text={'View Portfolio'} />
          </a>
        </div>
        <div>
          <button
            onMouseOver={() => mouseHover('fill')}
            onMouseOut={mouseOut}
            className="custom-next-btn cutsom__btn"
          >
            <img
              src={nextArrow}
              alt="nextarr"
            />
          </button>
        </div>
      </div>
    </>
  );
}
