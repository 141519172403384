import "./style.scss"
import { Footer, MouseRing, Navbar } from "../../components"
import { AboutHero, PolicyPageMainSection } from "../../layout"

const Index = () => {
  const policiesData = [
    {
      policy: "1. Overview",
      detail:
        "At Apexverse, we are dedicated to delivering high-quality software development, marketing, and design services. We work closely with clients to ensure that our services meet their expectations. However, we understand that there may be circumstances where you are not satisfied with the service received, and this policy outlines the terms under which refunds may be provided.",
    },

    {
      policy: "2. 14-Day Refund Policy",
      detail:
        "We offer a 14-day refund window for all service purchases, subject to the conditions outlined below:\nEligibility for Refund: If you are dissatisfied with the service and wish to request a refund, you must notify us within 14 days of the start date of the service or contract. After this period, we are not obligated to provide a refund unless otherwise stated in this policy.\nFull Refunds: If your request is made within the 14-day window and no significant work has commenced, you will be eligible for a full refund.",
    },

    {
      policy: "3. No Automatic Refunds After 14 Days",
      detail:
        "If the 14-day period has passed and substantial work on the service has already been delivered or completed, we are not bound to provide a refund. In such cases, any refund or compensation will be entirely at our discretion.",
    },
    {
      policy: "4. Exceptional Circumstances",
      detail:
        "Refunds may be considered in the following scenarios:\nFailure to Deliver Services: If we are unable to deliver the agreed-upon service due to our fault, you may be entitled to a full or partial refund.\nNon-Performance: If the service provided does not meet the agreed-upon standards or terms, we will attempt to rectify the issue. If resolution is not possible, a partial refund may be considered based on the work completed.",
    },
    {
      policy: "5. Cancellations",
      detail:
        "Pre-Work Cancellations: If you cancel a service before work has commenced and within the 14-day refund period, you will be eligible for a full refund.\nMid-Project Cancellations: If you cancel the service after significant work has commenced, a refund for any unused portion will be at our discretion, based on the progress made.",
    },
    {
      policy: "6. Custom Services & Non-Refundable Deposits",
      detail:
        "For certain custom services, including design and development work, a non-refundable deposit may apply. This deposit covers initial preparation and setup costs and is not refundable once the service begins, even if requested within the 14-day window.",
    },

    {
      policy: "7. How to Request a Refund",
      detail:
        "To request a refund, please contact us at info@apexverse.co with your project details and the reason for your request. We will review your case and respond within 24 hours.",
    },

    {
      policy: "8. Dispute Resolution",
      detail:
        "We aim to resolve refund disputes amicably. In cases where a resolution cannot be reached, you retain the right to seek further action under UK law.For questions regarding this policy, contact us at info@apexverse.co.",
    },
  ]

  return (
    <div className="root">
      <MouseRing />
      <Navbar />
      <AboutHero title={"REFUND POLICY"} />
      <PolicyPageMainSection policiesData={policiesData} />
      <Footer />
    </div>
  )
}

export default Index
