import React from "react";
import "./style.scss";
import serverImage from "../../../assets/images/services/creative-machine.png";
import designerGirl from "../../../assets/images/services/designer-girl.png";
import cloudImage from "../../../assets/images/services/cloud.png";
import CTABtn from "../../../components/buttons/CTABtn";
import { BsCheckLg } from "react-icons/bs";

const Index = () => {
  return (
    <div className="service__details__main container">
      <h2 data-aos="flip-right" className="service__detail__heading">
        RELIABLE AND ROBUST
      </h2>
      <div className="row p-0 m-0 details__sub__container">
        <div className="halfWidth p-0 m-0 image__section position-relative pt-4">
          <div className="cloud__container rtl">
            <img alt="cloud" src={cloudImage} />
          </div>
          <div className="cloud__container ltr bot">
            <img alt="cloud" src={cloudImage} />
          </div>
          <img alt="server" data-aos="fade-right" src={serverImage} />
        </div>
        <div
          data-aos="fade-up"
          className="halfWidth p-0 m-0 text__section pt-4"
        >
          <p>
            In an increasingly crowded field of fraudsters and mediocrity, our
            programming team stands out as a reputable and technologically
            proficient web development firm in the world.
          </p>
          <p>
            Our meticulous attention to detail in coding and conceptualization
            results in a long-lasting digital environment for your brand's
            online presence. As a consequence, you will have access to a fully
            working, highly secure, revenue-generating website.
          </p>
          <div className="mt-3">
            <CTABtn text={"ENQUIRE NOW"} />
          </div>
        </div>
      </div>
      <h2 data-aos="flip-right" className="mt-4 service__detail__heading">
        READY FOR AN EPIC VICTORY
      </h2>
      <div className="row p-0 m-0 mt-3 details__sub__container">
        <div className="halfWidth p-0 m-0 designerGirl image__section position-relative">
          <div className="cloud__container rtl">
            <img alt="cloud" src={cloudImage} />
          </div>
          <div className="cloud__container ltr bot">
            <img alt="cloud" src={cloudImage} />
          </div>
          <img
            alt="designer girl"
            data-aos="fade-down"
            className="girlImg"
            src={designerGirl}
          />
        </div>
        <div
          data-aos="fade-up"
          className="halfWidth p-0 m-0 text__section position-relative"
        >
          <p>Mega boost unlocked</p>
          <h3>WEB WINNING SOLUTIONS</h3>
          <div className="cloud__container rtl">
            <img alt="cloud" src={cloudImage} />
          </div>
          <div className="cloud__container ltr bot">
            <img alt="cloud" src={cloudImage} />
          </div>
          <p>
            With your great website, you may level the playing field and aim for
            nothing less than the highest score. How can your rivals get an
            advantage when ApexVerse is willing to go above and above for your
            success?
          </p>
          <p>Our website development arsenal has you covered:</p>
          <div className="list__item">
            <BsCheckLg color="#ffb300" size={17} /> Hosting with guaranteed
            uptime
          </div>
          <div className="list__item">
            <BsCheckLg color="#ffb300" size={17} /> SSL encryption and website
            security
          </div>
          <div className="list__item">
            <BsCheckLg color="#ffb300" size={17} /> Current and accurate
            analytics
          </div>
          <div className="list__item">
            <BsCheckLg color="#ffb300" size={17} /> Regular and optimized
            website maintenance
          </div>
          <div className="mt-4">
            <CTABtn text={"ENQUIRE NOW"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
