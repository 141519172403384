import React from "react";
import "./style.scss";
import { CTAButton } from "../../components";
import heroIcon from "../../assets/images/svg/hero.svg";
import heroMainImg from "../../assets/images/hero-main-img.png";

const Index = () => {
  return (
    <div className="hero__main">
      <div className="hero__sub__main container">
        <img alt="hero icon" className="hero__icon" src={heroIcon} />
        <div data-aos="fade-right" className="text__section">
          <h1>
            Top
            <br />
            <span>Website Development</span>
            <br />
            agency
          </h1>
          <p className="hero__description">
            We offer cutting-edge website design and application development
            solutions to propel your business's success, enhance its visibility,
            and expand its reach in the world and beyond
          </p>
          <div className="d-flex gap-4 btn__section">
            <CTAButton text={"Let's Connect"} />
            <CTAButton text={"Book a call"} />
          </div>
        </div>
        <div data-aos="fade-up" className="hero__image">
          <img src={heroMainImg} alt="hero" />
        </div>
      </div>
    </div>
  );
};

export default Index;
