import React from 'react'

const Index = ({ videoUrl }) => {

    return (
        <div>
            <video muted style={{ width: "100%" }} autoPlay playsInline>
                <source src={videoUrl} type="video/mp4" />
            </video>
        </div>
    )
}

export default Index