import React, { useContext } from "react";
import "./style.scss";
import { CTAButton } from "../../components";
import sketchTop from "../../assets/images/footer-top-texturev2.png";
import sketchBottom from "../../assets/images/footer-bottom-texturev2.png";
import dog from "../../assets/images/dog.png";
import deer from "../../assets/images/deer.png";
import cat from "../../assets/images/cat.png";
import chicken from "../../assets/images/chicken.png";
import lion from "../../assets/images/lion.png";
import { AuthContext } from "../../context";

const Index = () => {
  const { setCursor } = useContext(AuthContext);

  const images = [
    {
      src: dog,
      style: { marginLeft: 0, zIndex: 0 },
    },
    {
      src: chicken,
      style: { marginLeft: "-5rem", zIndex: 0 },
    },
    {
      src: lion,
      style: { marginLeft: "-5rem", zIndex: 11 },
    },
    {
      src: cat,
      style: { marginLeft: "-5rem", zIndex: 0 },
    },
    {
      src: deer,
      style: { marginLeft: "-4rem", zIndex: 0 },
    },
  ];

  const mouseHover = () => setCursor("hidden");
  const mouseOut = () => setCursor("");

  // chicken lion cat
  return (
    <div className="about__main">
      <img className="sketh__images" src={sketchBottom} alt="sketch top" />
      <div className="about__sub__section">
        <div className="flex__section container">
          <div data-aos="fade-right" className="images__area d-flex">
            {images?.map((v, i) => {
              return (
                <div
                  onMouseOver={mouseHover}
                  onMouseOut={mouseOut}
                  className={`about__images ${i !== 0 ? "marginOnMob" : ""}`}
                  style={v?.style}
                  key={i}
                >
                  <img src={v.src} alt={v?.src} />
                </div>
              );
            })}
          </div>
          <div className="about__area">
            <div className="about__text">
              <h2 className="d-flex">
                <div data-aos="flip-right">A</div>
                <div data-aos="flip-right">B</div>
                <div data-aos="flip-right">O</div>
                <div data-aos="flip-right">U</div>
                <div data-aos="flip-right">T</div>
              </h2>
              <div className="d-flex justify-content-center">
                <p data-aos="fade-up">
                  Your digital aspirations are not so farfetched. Apexverse
                  makes it feasible to achieve top search engine rankings and
                  organic visitors. Please contact our team, and we will work
                  around the clock to bring your concept to reality.
                </p>
              </div>
              <div
                data-aos="fade-up"
                className="d-flex justify-content-center btn__section"
              >
                <CTAButton text={"Discuss a brief"} />
              </div>
            </div>
          </div>
        </div>
        <img className="sketh__images" src={sketchTop} alt="sketch top" />
      </div>
    </div>
  );
};

export default Index;
