import React from 'react';
import './style.scss';
import { Footer, MouseRing, Navbar } from '../../components';
import { ServiceDetails, ServiceHero } from '../../layout';
import brandingVideo from '../../assets/video/design-branding-vid.mp4'


const Index = () => {
    return (
        <div className='root'>
            <MouseRing />
            <Navbar />
            <ServiceHero videoUrl={brandingVideo} />
            <ServiceDetails />
            <Footer />
            <div className='home__main'>
            </div>
        </div>
    )
}

export default Index