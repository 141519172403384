import React, { useContext } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import star from "../../../assets/images/svg/star.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.scss";

// import required modules
import { AuthContext } from "../../../context";

export default function ReviewCarousel({ reviews }) {
  const { setCursor } = useContext(AuthContext);

  const mouseHover = () => setCursor("hidden");
  const mouseOut = () => setCursor("");

  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        lazy={true}
        pagination={false}
        navigation={false}
        className="home__reviews__swipper"
      >
        {reviews?.map((v, i) => {
          return (
            <SwiperSlide onMouseOver={mouseHover} onMouseOut={mouseOut} key={i}>
              <div
                onMouseOver={mouseHover}
                onMouseOut={mouseOut}
                className="carousel__card"
              >
                <div
                  onMouseOver={mouseHover}
                  onMouseOut={mouseOut}
                  className="slider__text__section"
                >
                  <div className="stars__section">
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                  </div>
                  <div className="pera__section">
                    <p>
                      {v?.description}
                    </p>
                  </div>
                  <div className="name__section">
                    <h2>{v?.name}</h2>
                    <p>{v?.designation}</p>
                  </div>
                </div>
                <div className="slider__img__section">
                  <img
                    src="https://swiperjs.com/demos/images/nature-2.jpg"
                    alt="nature"
                  />
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
