import React from 'react';
import './style.scss';
import { Footer, MouseRing, Navbar } from '../../components';
import {  AboutHero, AboutUsReview, CardsSection } from '../../layout';
import { ContactForm } from '../../layout/contactUs';

const Index = () => {
    return (
        <div className='root'>
            <MouseRing />
            <Navbar />
            <AboutHero title="PRICING" />
            <CardsSection />
            <AboutUsReview />
            <ContactForm />
            <Footer />
            <div className='home__main'>
            </div>
        </div>
    )
}

export default Index