import React from 'react'
import './style.scss'
import { CTAButton } from '../../../components'
import sketchTop from '../../../assets/images/footer-top-texturev2.png'
import sketchBottom from '../../../assets/images/footer-bottom-texturev2.png'
import zebra from '../../../assets/images/contact/zebra.png'


const Index = () => {

    // chicken lion cat
    return (
        <div className='contact__about__main2'>
            <img className='sketh__images' src={sketchBottom} alt='sketch top' />
            <div className='about__sub__section'>
                <div className='flex__section container'>
                    <div className='about__area'>
                        <div>
                            <p>
                                We make no compromises on value and leave no room for concession in quality.

                            </p>
                            <p>In fact, our quest for perfection is robust and progress is continuous. We believe in going the extra mile for customer satisfaction and scaling our services for a future-proof package of digital solutions.

                            </p>
                            <div className='btn__section'>
                                <CTAButton text={"Get Discount"} />
                            </div>
                        </div>
                    </div>
                    <div className='images__area'>
                        <div className='img__container position-relative'>
                            <img src={zebra} alt='zebra' />
                        </div>
                    </div>

                </div>
                <img className='sketh__images' src={sketchTop} alt='sketch top' />
            </div>
        </div>
    )
}

export default Index
