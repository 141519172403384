import { useEffect } from 'react';
import AppRouter from './config/AppRouter';
import './App.scss'
import AuthProvider from './context';
import Aos from 'aos';
import "aos/dist/aos.css";
import { WhatsappBtn } from './components';

function App() {

  useEffect(() => {
    Aos.init()
  }, [])

  return (
    <AuthProvider>
      <AppRouter />
      <WhatsappBtn />
    </AuthProvider>
  );
}

export default App;
