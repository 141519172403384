import React from "react";
import "./style.scss";
import { Lines } from "../../../components";
import sketchTop from "../../../assets/images/footer-top-texturev2.png";
import sketchBottom from "../../../assets/images/footer-bottom-texturev2.png";
import faceImage from "../../../assets/images/face.png";

const Index = () => {
  // chicken lion cat
  return (
    <div className="hero__about__main">
      <img className="sketh__images" src={sketchBottom} alt="sketch top" />
      <div className="about__sub__section">
        <div className="flex__section container">
          <div className="about__area">
            <p>
              You have a valuable brand with a deep purpose and distinct
              personality. We happen to be the catalysts that can propel it to
              unrivaled growth and recognition.
            </p>
            <p>
              Our AI-powered, future-proof, and technologically advanced
              solutions position us as your brand's critical partners in this
              fast-paced, rapidly evolving digital landscape. AppexVerse is
              already a household name for 137+ B2B companies in United Kingdom
              and elsewhere. You're invited to join our family.
            </p>
          </div>
          <div className="images__area">
            <img src={faceImage} />
          </div>
        </div>
        <img
          className="sketh__images bottom__sketch"
          src={sketchTop}
          alt="sketch top"
        />
      </div>
      <div className="line__section">
        <Lines
          fontFamily={"Anton"}
          fontSize={52}
          text={"Our Vision"}
          dir={"ltr"}
          side={"right"}
        />
        <Lines
          fontFamily={"Anton"}
          fontSize={52}
          layer={2}
          text={"Our Vision"}
          dir={"rtl"}
          side={"right"}
        />
      </div>
    </div>
  );
};

export default Index;
