import React from "react";
import "./style.scss";

import HeadingWithTag from "../../components/headings/HeadingWithTag";

import ourTechnologyStack from "../../assets/images/services/ourTechnologyStack.png";
import whyChooseUs from "../../assets/images/services/whyChooseUs.png";
import OurService from "../../assets/images/services/OurService.png";

// Frontend
// We power up your site's appearance with dynamic visuals and user-friendly navigation.

// Backend
// We leave no stone unturned in guaranteeing your site's data processing and seamless functioning.

// Mobile Development
// We promise mobile - responsiveness and cross - browser compatibility for every app and website.

// Cloud Services
// We leverage the latest and scalable cloud computing platforms to store your data and host your site.

const Index = () => {
  return (
    <div className="app__development__main">
      <div className="d-flex justify-content-center heading__section">
        <HeadingWithTag text={"App Development Company"} />
      </div>
      <div className="content__section container">
        {/* <VirtualCarousel /> */}
        <div className="m-0 p-0 content__card">
          <div className="text__section col-md-7">
            <h2 className="text__heading">
              Our
              <br />
              <span>Development</span>
              <br />
              EcoSystem
            </h2>
            <div className="d-flex flex-wrap">
              <div className="col-md-6 p-0 m-0 service__card">
                <h4>Frontend</h4>
                <p>
                  We power up your site's appearance with dynamic visuals and
                  user-friendly navigation.
                </p>
              </div>
              <div className="col-md-6 p-0 m-0 service__card">
                <h4>Backend</h4>
                <p>
                  We leave no stone unturned in guaranteeing your site's data
                  processing and seamless functioning.
                </p>
              </div>
              <div className="col-md-6 p-0 m-0 service__card">
                <h4>Mobile Development</h4>
                <p>
                  We promise mobile-responsiveness and cross-browser
                  compatibility for every app and website.
                </p>
              </div>
              <div className="col-md-6 p-0 m-0 service__card">
                <h4>Cloud Services</h4>
                <p>
                  We leverage the latest and scalable cloud computing platforms
                  to store your data and host your site.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-5 img__section">
            <img src={ourTechnologyStack} alt="out tech tech" />
          </div>
        </div>

        <div className="m-0 p-0 content__card">
          <div className="text__section col-md-7">
            <h2 className="text__heading">
              Website
              <br />
              <span>Development</span>
              <br />
              Service
            </h2>
            <div className="d-flex flex-wrap">
              <div className="col-md-6 p-0 m-0 service__card">
                <h4>Ecommerce</h4>
                <p>
                  Customise your selling options, and secure your revenue
                  stream!
                </p>
              </div>
              <div className="col-md-6 p-0 m-0 service__card">
                <h4>CMS</h4>
                <p>
                  Manage, update, and modify your online platform with custom
                  CMS development.
                </p>
              </div>
              <div className="col-md-6 p-0 m-0 service__card">
                <h4>UI/UX Design</h4>
                <p>
                  Stun and impress your users with a visually appealing (UI) and
                  (UX) design.
                </p>
              </div>
              <div className="col-md-6 p-0 m-0 service__card">
                <h4>web maintenance</h4>
                <p>
                  Receive ongoing maintenance services to secure your website.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-5 img__section">
            <img src={OurService} alt="our services" />
          </div>
        </div>

        <div className="m-0 p-0 content__card">
          <div className="text__section col-md-7">
            <h2 className="text__heading">
              Best Website <br />
              <span>Designer</span>
            </h2>
            <p className="text__description">
              Apexverse effortlessly curates cutting-edge digital solutions. We
              look for talented webmasters and keep up with technological
              changes. We always deliver on schedule and to the satisfaction of
              our clients by following a roadmap based on agility, efficiency,
              and excellence.
            </p>
          </div>
          <div className="col-md-5 img__section">
            <img src={whyChooseUs} alt="about us" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
