import React, { useContext, useState } from 'react'
import './style.scss'
import { CgMenuRight } from "react-icons/cg";
import NavbarDrawer from './NavbarDrawer';
import { AuthContext } from '../../context';

const Index = () => {

    const { setCursor } = useContext(AuthContext);

    const [btnHovered, setBtnHovered] = useState(false)
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const openDrawer = () => setDrawerOpen(true);
    const closeDrawer = () => setDrawerOpen(false);

    const mouseHover = () => {
        setCursor('fill')
        setBtnHovered(true)
    };
    const mouseLeave = () => {
        setCursor('')
        setBtnHovered(false)
    };

    return (
        <div className='navbar__main'>
            <div className='logo__section'>
                <a href="/">
                <img src='/logo512.png' alt='logo' style={{maxWidth:200}} />
                </a>
            </div>
            <div className='menu__section'>
                <button onMouseOver={mouseHover} onMouseOut={mouseLeave} onClick={openDrawer} className='menu__btn'>
                    <CgMenuRight color={btnHovered ? '#ffb300' : '#fff'} size={50} />
                </button>
            </div>
            <NavbarDrawer open={drawerOpen} onClose={closeDrawer} />
        </div>
    )
}

export default Index
