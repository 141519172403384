import React from 'react';
import './style.scss';
import { Footer, MouseRing, Navbar } from '../../components';
import { ContactForm, ContactHero, ContactTextSection, ContactTextSection2, } from '../../layout/contactUs';

const Index = () => {
    return (
        <div className='root'>
            <MouseRing />
            <Navbar />
            <ContactHero />
            <ContactForm />
            <ContactTextSection />
            <ContactTextSection2 />
            <Footer />
            <div className='home__main'>
            </div>
        </div>
    )
}

export default Index