import { useContext } from "react";
import useMousePosition from "../../utils/hooks/usemouseposition";
import "./style.scss";
import { AuthContext } from "../../context";

const DotRing = () => {
  // 1.
  const { x, y } = useMousePosition();
  const { cursor } = useContext(AuthContext)
  return (
    <>
      {/* 2. */}
      <div
        style={{ left: `${x}px`, top: `${y}px` }}
        className={`ring ${cursor}`}
      ></div>
      {/* 3. */}
      <div
        className={`dot ${cursor}`}
        style={{ left: `${x}px`, top: `${y}px` }}
      ></div>
    </>
  );
};

export default DotRing;