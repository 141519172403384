import pic1 from './Book Illustration-1.webp';
import pic2 from './Book Illustration-2.webp';
import pic3 from './Book Illustration-3.webp';
import pic4 from './Book Illustration-4.webp';
import pic5 from './Book Illustration-5.webp';
import pic6 from './Book Illustration-6.webp';
import pic7 from './Book Illustration-7.webp';
import pic8 from './Book Illustration-8.webp';

export let illusPictures = [pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8];
