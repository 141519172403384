import React from 'react'
import './style.scss'
import sketchBottom from '../../assets/images/top__texture2.png'
import sketchTop from '../../assets/images/bottomTexture2.png'
import HeadingWithTag from '../../components/headings/HeadingWithTag'
import telephone from '../../assets/images/telephone.png'
import CTABtn from '../../components/buttons/CTABtn'


const Index = () => {

  // chicken lion cat
  return (
    <div className='contact__main'>
      <img className='sketh__images' src={sketchBottom} alt='sketch top' />
      <div className='bg__pink'>
        <div className='contact__sub__section container pt-4'>
          <div className='flex__section'>
            <div className='d-flex justify-content-center position-relative'>
              <div className='back__box' />
              <img className='telephone__image' src={telephone} alt='telephone' />
              <HeadingWithTag customClass={"contact__heading"} black text="Contact" />
            </div>
            <div className='d-flex justify-content-end text__section'>
              <p>
                Let's
                <br />
                Get Started
              </p>
            </div>
            <div className='connect_btn__section d-flex justify-content-end text__section mb-4'>
              <CTABtn text={"Let's Connect"} />
            </div>
          </div>
        </div>
        <img className='sketh__images' src={sketchTop} alt='sketch top' />
      </div>
    </div>
  )
}

export default Index
