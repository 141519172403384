import React from 'react'
import './style.scss'
import HeadingWithTag from '../../components/headings/HeadingWithTag'
import ReviewCarousel from './components/Carousel'


const Index = () => {

  const reviews = [
    {
      name: "Becca",
      description: "Moe has brought our dreams to life with our insane website design. Hes made it possible for us to intergrate all our wants and needs into one beautiful website. Cannot recommend more :)",
      designation: "Cherry Juice Beauty"
    },
    {
      name: "Iarere",
      description: "Moe has continuously supported my consulting business and provided expert advice on improving my website for the last 3 years. His professionalism is exemplary, and flexible in understanding client needs with customization. Highly recommend Moe and  Tech Ciaga.",
      designation: "ICGSMS"
    },
    {
      name: "Sash",
      description: "Worked with Moe, he was amazing throughout, had input in the design and added his own ideas. Was flexible in wanting to make the site the way I wanted and made sure I was happy before asking for payment. Overall would recommend.",
      designation: "Start My Business"
    },
    {
      name: "Alicia",
      description: "Cannot rate Moe's website design highly enough. We are technological pygmies and the whole process was so new to us and a little but daunting, but we knew we needed a website to get our business up and running. We had an idea of content but struggled to visualise finished product. This is where Moe's talents really came into play - he stepped us through each step of the website design process and was very patient with us. His recommendations for layout and design were great. We are extremely happy with the finished product and could not recommend more highly. Thank you Moe & team! Alicia",
      designation: "Backflow Testing Co."
    },
    {
      name: "Michael",
      description: "Working with Moe from  Tech Ciaga has been really enjoyable. If you ever have questions or are in need of help he is always there with almost an instant response. I highly recommend hiring Moe for your projects.",
      designation: "Solidification Solutions"
    },
    {
      name: "Christian",
      description: "Working with  Tech Ciaga to bring my website to life was a great experience. I worked with Moe and he was outstanding! - He was accomodating to my needs and assisted me 100% of the way, any request I had he completed for me. I would recommend Moe and this company to anyone who needs assistance creating a website",
      designation: "Fosfit"
    },
    {
      name: "Rohan Delpechitra",
      description: "I would like to highly commend Mark for developing a website for me. Mark was very helpful, friendly and professional at all times. I would highly recommend him, his customer service was second to none. Thank you Mark!",
      designation: "Global Soccer Tshirt"
    },
    {
      name: "Alicia Summer",
      description: "I have had a great experience working with The  Tech Ciaga. At every step of the way my designer has kept me informed and our communication was constant with near immediate responses. They have done an outstanding job of my website, far exceeding my expectations.",
      designation: "Sanctuary Skin And Laser Clinic"
    },
    {
      name: "Laura",
      description: "Moe is an incredible website developer! Nothing was too much of a task for him to complete and he was very patient throughout the entire process.Moe was able to include text animation, videos and amazing imagery across the whole website. Highly recommend for a start up or large organisation.Moe will be able to cater to your budget.",
      designation: "SYNC Recruitment"
    },
    {
      name: "Kevin Dawson",
      description: "My experience with Tech Ciaga in building my web site was truly incredible. They built the web site, created my Logo, business card and stationary templates. A special mention to Moe Sinclair who made sure that every request I made was followed up by either his team or himself. It is rare that a company cares as much about the result as you do. But Moe and his team… they care. Moe’s technical knowledge on web site design is not only first rate, but brilliant. Trust is hard to acquire but easily broken. But Moe established trust early on so that every suggestion he made…I knew was to my organisations’ benefit….and and I’m glad I implemented those suggestions. Cannot recommend a web design company more highly than Tech Ciaga. You can’t go wrong.",
      designation: "Video Legacy Productions"
    },
    {
      name: "Mariama",
      description: "Thank you, Moe for creating a beautiful and friendly website for me.",
      designation: "Doms Fashion"
    },
    {
      name: "Rebecca Montana",
      description: "Moe and the guys at Tech Ciaga are excellent. Fast changes to website and always willing to help. Highly recommend",
      designation: "Dog Haus"
    },
    {
      name: "Lara",
      description: "Working with Moe was effortless, high-level of communication followed by a quick turnaround. Affordable. Knowledgeable and professional. We are delighted with our website, it is so user-friendly for both PC and mobile phones. Don’t hesitate in engaging with the Tech Ciaga,  We can not thank you enough, we love it!",
      designation: "DHCS"
    },
    {
      name: "Mark Langdon",
      description: "Tech Ciaga have been absolutely amazing. I had no idea about website design and they guided me through the process and produced a website far beyond my expectations. They accommodated all my requests and were extremely patient when I was unsure about things and provided options. I would highly recommend Tech Ciaga if you are starting up a new business.",
      designation: "On The Mark Support"
    },
    {
      name: "Daniel",
      description: "So happy with the website and the service you guys offered. Thanks Abby and Joe for assisting me throughout the process. You guys are doing an amazing job.",
      designation: "Ermine"
    },
    {
      name: "SAM",
      description: "Great work. Fantastic Job done by Abby and Joe. Loved their professionalism.",
      designation: "Insight Electric"
    }
  ]


  return (
    <div className='reviews__main'>
      
      <div className='d-flex justify-content-center'>
        <HeadingWithTag customClass={"reviews__heading"} text={"Reviews"} />
      </div>
      <div data-aos="fade-down">
        <ReviewCarousel reviews={reviews} />
      </div>
      <div className='d-flex align-items-center justify-content-center gap-2'>
        {reviews?.map((v, i) => {
          return <div className='pagination__dot' key={i}>
            <div />
          </div>
        })}
      </div>
    </div>
  )
}

export default Index
