import React from "react";
import "./style.scss";
import serverImage from "../../../assets/images/services/DesignAbout-img.png";
import designerGirl from "../../../assets/images/services/designer-girl.png";
import SplashBulb from "../../../assets/images/services/game-over.png";
import cloudImage from "../../../assets/images/services/cloud.png";
import CTABtn from "../../../components/buttons/CTABtn";
import { BsCheckLg } from "react-icons/bs";

const Index = () => {
  return (
    <div className="service__details__main container">
      <h2 data-aos="flip-right" className="service__detail__heading">
        Gear up! Time to unveil what's inside.
      </h2>
      <div className="row p-0 m-0 details__sub__container">
        <div className="halfWidth p-0 m-0 image__section position-relative pt-4">
          <div className="cloud__container rtl">
            <img alt="cloud" src={cloudImage} />
          </div>
          <div className="cloud__container ltr bot">
            <img alt="cloud" src={cloudImage} />
          </div>
          <img alt="server" data-aos="fade-right" src={serverImage} />
        </div>
        <div
          data-aos="fade-up"
          className="halfWidth p-0 m-0 text__section pt-4"
        >
          <p>Warning. Premium services are now accessible.</p>
          <p>
            ApexVerse's exceptional team is ready to exceed your expectations
            for an average web designer.
          </p>
          <p>
            We combine cutting-edge UI/UX features with an advanced information
            architecture to create a website that catches every visitor's
            attention and stands out from the crowd.
          </p>
          <div className="list__item">
            <BsCheckLg color="#ffb300" size={17} /> Appeals the eye
          </div>
          <div className="list__item">
            <BsCheckLg color="#ffb300" size={17} /> Attracts the visitors
          </div>
          <div className="list__item">
            <BsCheckLg color="#ffb300" size={17} /> Beats the competition
          </div>
          <div className="mt-3">
            <CTABtn text={"ENQUIRE NOW"} />
          </div>
        </div>
      </div>
      <div className="row p-0 m-0 mt-4 details__sub__container">
        <div className="halfWidth p-0 m-0 text__section">
          <h2 data-aos="flip-right" className="position-relative">
            <div className="cloud__container rtl">
              <img alt="cloud" src={cloudImage} />
            </div>
            <div className="cloud__container ltr bot">
              <img alt="cloud" src={cloudImage} />
            </div>
            Starting up
          </h2>
          <p data-aos="fade-up">
            Web design agency United Kingdom could be merely a phrase you seek
            up online. However, for us, it is a distinguishing feature, an
            important part of our identity.
          </p>
          <p data-aos="fade-up">
            As a result, we prioritize integrity and dedicate our creative
            expertise to meeting your needs.
          </p>
          <p data-aos="fade-up">
            You could be at the beginning of your entrepreneurial adventure or
            have already decided to rebrand.
          </p>
          <p data-aos="fade-up">
            Regardless, our bespoke solutions consistently place us as your best
            alternative and the leading web design business in the United
            Kingdom.
          </p>
          <div data-aos="fade-up" className="mt-3">
            <CTABtn text={"ENQUIRE NOW"} />
          </div>
        </div>
        <div
          data-aos="fade-left"
          className="halfWidth p-0 m-0 image__section bulb__section"
        >
          <img alt="bulb" src={SplashBulb} />
        </div>
      </div>
      <div className="row p-0 m-0 mt-3 details__sub__container">
        <div className="halfWidth p-0 m-0 designerGirl image__section position-relative">
          <div className="cloud__container rtl">
            <img alt="cloud" src={cloudImage} />
          </div>
          <div className="cloud__container ltr bot">
            <img alt="cloud" src={cloudImage} />
          </div>
          <img
            alt="girl"
            data-aos="fade-right"
            className="girlImg"
            src={designerGirl}
          />
        </div>
        <div className="halfWidth p-0 m-0 text__section position-relative">
          <div className="cloud__container rtl">
            <img alt="cloud" src={cloudImage} />
          </div>
          <div className="cloud__container ltr bot">
            <img alt="cloud" src={cloudImage} />
          </div>
          <p data-aos="fade-up">
            The positive. The great. The highest being.
            <p data-aos="fade-up">
              You won't find anything less at Apexerse. In truth, we speak a
              language that only results in an outstanding website. We focus on
              users, curate designs for accessibility, and optimize each web
              design feature's responsiveness.
            </p>
            <p data-aos="fade-up">
              Thus, among a swarm of noobs leading every other web design agency
              in the World , you are welcome to trust the experts.{" "}
            </p>
          </p>
          <div data-aos="fade-up" className="mt-4">
            <CTABtn text={"ENQUIRE NOW"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
