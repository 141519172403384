import pic1 from './mp1.webp';
import pic2 from './mp2.webp';
import pic3 from './mp3.webp';
import pic4 from './mp4.webp';
import pic5 from './mp5.webp';
import pic6 from './mp6.webp';
import pic7 from './mp7.webp';
import pic8 from './mp8.webp';
import pic9 from './mp9.webp';
import pic10 from './mp10.webp';
import pic11 from './mp11.webp';
import pic12 from './mp12.webp';

export let mobilePictures = [
  pic1,
  pic2,
  pic3,
  pic4,
  pic5,
  pic6,
  pic7,
  pic8,
  pic9,
  pic10,
  pic11,
  pic12,
];
