import React from 'react'
import './style.scss'
import texture from '../../../assets/images/heading__texture.png'
import CTABtn from '../../../components/buttons/CTABtn'
import telephone from '../../../assets/images/telephone.png'


const Index = () => {
  return (
    <div className='contact__hero__main container'>
      <div className='image__section'>
        <div className="image__box">
          <div className='image__sub__bos'>

          </div>
          <div className='bulb__section'>
            <img src={telephone} />
          </div>
        </div>
      </div>
      <div className="text__section">
        <h2 className='d-flex justify-content-end'>
          <img src={texture} className='right__texture' />
          <img src={texture} className='left__texture' />
          <div data-aos="flip-right">C</div>
          <div data-aos="flip-right">O</div>
          <div data-aos="flip-right">N</div>
          <div data-aos="flip-right">T</div>
          <div data-aos="flip-right">A</div>
          <div data-aos="flip-right">C</div>
          <div data-aos="flip-right">T</div>
        </h2>
        <p data-aos="fade-up" className='about__description'>
          LET’S GET
          <br />
          THINGS STARTED
        </p>
        <div data-aos="fade-up" className='d-flex justify-content-end'>
          <CTABtn text={"SUBMIT A BRIEF"} />
        </div>
      </div>
    </div>
  )
}

export default Index