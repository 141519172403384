import React from "react"
import "./style.scss"
import texture from "../../../assets/images/heading__texture.png"
import CTABtn from "../../../components/buttons/CTABtn"
import aboutBulb from "../../../assets/images/aboutbulb.png"

const Index = ({ title }) => {
  const policyPage = title.toLowerCase().includes("policy")

  return (
    <div
      className="about__hero__main container"
      style={{
        justifyContent: policyPage ? "center" : "flex-end",
      }}>
      {!policyPage && (
        <div className="image__section">
          <div className="image__box">
            <div className="image__sub__bos"></div>
            <div className="bulb__section">
              <img
                src={aboutBulb}
                alt="bulb"
              />
            </div>
          </div>
        </div>
      )}
      <div className="text__section">
        <h2
          className="d-flex"
          style={{
            justifyContent: policyPage ? "center" : "flex-end",
            flexWrap: policyPage ? "wrap" : "nowrap",
          }}>
          {!policyPage && (
            <>
              <img
                src={texture}
                alt="texture"
                className="right__texture"
              />
              <img
                src={texture}
                alt="texture2"
                className="left__texture"
              />
            </>
          )}
          {title.split("").map((text, index) => (
            <div
              data-aos="flip-right"
              key={index}>
              {text}
            </div>
          ))}
        </h2>
        {!policyPage && (
          <>
            <p
              data-aos="fade-up"
              className="about__description">
              The web experts
            </p>
            <div
              data-aos="fade-up"
              className="btn__section">
              <CTABtn text={"SUBMIT A BRIEF"} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Index
