import React from "react"
import "./style.scss"
import { Footer, MouseRing, Navbar } from "../../components"
import {
  About,
  AppCompany,
  ContactSection,
  CardsSection,
  FAQ,
  HeroSection,
  Impress,
  OtherCommunities,
  Portfolio,
  Reviews,
  SecondImress,
} from "../../layout"

const Index = () => {
  return (
    <div className="root">
      <MouseRing />
      <Navbar />
      <HeroSection />
      <About />
      <Portfolio />
      <Impress />
      <AppCompany />
      <OtherCommunities />
      <Reviews />
      <CardsSection homepg/>
      <SecondImress />
      <FAQ />
      <ContactSection />
      <Footer />
      <div className="home__main"></div>
    </div>
  )
}

export default Index
