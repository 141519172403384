import React from "react";
import "./style.scss";
import { Lines } from "../../../components";
import sketchTop from "../../../assets/images/footer-top-texturev2.png";
import sketchBottom from "../../../assets/images/footer-bottom-texturev2.png";
import mapball from "../../../assets/images/mapball.png";
import mapStand from "../../../assets/images/mapstand.png";

const Index = () => {
  // chicken lion cat
  return (
    <div className="hero__about__main2">
      <img className="sketh__images" src={sketchBottom} alt="sketch top" />
      <div className="about__sub__section">
        <div className="flex__section container">
          <div className="images__area">
            <div
              data-aos="fade-right"
              className="img__container position-relative"
            >
              <img className="map__ball" src={mapball} alt="mapball" />
              <img className="map__stand" src={mapStand} alt="mapstand" />
            </div>
          </div>
          <div data-aos="fade-up" className="about__area">
            <p>
              At Apexverse, we dare to imagine the unimaginable, reach new
              heights, and transform the digital environment. We want to be at
              the forefront of innovation and change in an environment driven by
              fast emerging trends and AI-powered tools.
            </p>
            <p>
              Apexverse's objective is to create agile, competitive, and
              timeless digital solutions that underpin your company's relevance
              and ensure its long-term success.
            </p>
          </div>
        </div>
        <div className="line__section">
          <Lines text={"Our Vision"} dir={"ltr"} side={"left"} />
          <Lines layer={2} text={"Our Vision"} dir={"rtl"} side={"left"} />
        </div>
        <img
          className="sketh__images bottom__sketch"
          src={sketchTop}
          alt="sketch top"
        />
      </div>
    </div>
  );
};

export default Index;
