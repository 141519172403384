import React from 'react'
import './style.scss'
import texture from '../../assets/images/heading__texture.png'

const HeadingWithTag = ({text,black,customClass}) => {
    return (
        <div data-aos="flip-right" className={`app__development__heading ${customClass ?? ""}`}>
            <img className='position-absolute texture__left' src={texture} alt='texture' />
            <img className='position-absolute texture__right' src={texture} alt='texture' />
            <h2 className={black ? "color__black":""}>{text}</h2>
        </div>
    )
}

export default HeadingWithTag
