import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { About, Branding, Contact, Home, Portfolio, Pricing, Seo, WebDesign, WebDevelopment, PrivacyPolicy, RefundPolicy } from '../views'

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services/webdevelopment" element={<WebDevelopment />} />
        <Route path="/services/designbranding" element={<Branding />} />
        <Route path="/services/webdesign" element={<WebDesign />} />
        <Route path="/services/digitalmarketing" element={<Seo />} />
        <Route path="/packages" element={<Pricing />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
