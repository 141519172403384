import pic1 from './13.webp';
import pic2 from './16.webp';
import pic3 from './2.webp';
import pic4 from './239134278_531904164781170_6616274472565999155_n.webp';
import pic5 from './3.webp';
import pic6 from './3f05abded88b1d19839e7d084283ba8c.webp';
import pic7 from './4.webp';
import pic8 from './5.webp';
import pic9 from './MUSIC ARTIST NATION-03.webp';
import pic10 from './final logo.webp';
import pic11 from './flamingo logo-01.webp';

export let logoPictures = [
  pic1,
  pic2,
  pic3,
  pic4,
  pic5,
  pic6,
  pic7,
  pic8,
  pic9,
  pic10,
  pic11,
];
