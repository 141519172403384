import React, { useState } from "react"
import "./style.scss"
import { CTAButton } from "../../../components"
import sketchTop from "../../../assets/images/footer-top-texturev2.png"
import sketchBottom from "../../../assets/images/footer-bottom-texturev2.png"
import { BsCheckLg } from "react-icons/bs"
import HeadingWithTag from "../../../components/headings/HeadingWithTag"

const Index = ({ homepg }) => {
  const [activeTab, setActiveTab] = useState(0)

  const tabs = [
    "Website",
    "Ecommerce",
    "Animation",
    "SEO",
    "Combo",
    "Social Media",
  ]

  const changeTab = (tab) => setActiveTab(tab)

  const userRegion = Intl.DateTimeFormat().resolvedOptions().timeZone.split('/')[0].toLowerCase()

  const currency = userRegion === 'europe' ? '€' : '$'

  const cards = {
    Website: [
      {
        name: "Startup Website",
        pricing: currency +"245",
        cutPrice: currency +"299",
        features: [
          "5 Stock Photos",
          "5 Page Website",
          "3 Banner Design",
          "1 jQuery Slider Banner",
          "FREE Google Friendly Sitemap",
          "Complete W3C Certified HTML",
          "Facebook Page Design",
          "Twitter Page Design",
          "YouTube Page Design",
          "100% Satisfaction Guarantee",
          "100% Unique Design Guarantee",
          "100% Money Back Guarantee *",
          "Mobile Responsive will be Additional " + currency + "99*",
          "CMS will be Additional " + currency + "149*",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Professional Website",
        pricing: currency +"695",
        cutPrice: currency +"1390",
        features: [
          "10 Unique Pages Website",
          "CMS / Admin Panel Support",
          "Mobile Responsive",
          "5 Stock images",
          "3 Banner Designs",
          "1 jQuery Slider Banner",
          "FREE Google Friendly Sitemap",
          "Complete W3C Certified HTML",
          "Facebook Page Design",
          "Twitter Page Design",
          "YouTube Page Design",
          "Complete Deployment",
          "100% Satisfaction Guarantee",
          "100% Unique Design Guarantee",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Elite Website",
        pricing: currency +"995",
        cutPrice: currency +"1990",
        features: [
          "Upto 15 Unique Pages Website",
          "Conceptual and Dynamic Website",
          "Mobile Responsive",
          "Online Reservation/Appointment Tool (Optional)",
          "Online Payment Integration (Optional)",
          "Custom Forms",
          "Lead Capturing Forms (Optional)",
          "Striking Hover Effects",
          "Newsletter Subscription (Optional)",
          "Newsfeed Integration",
          "Social Media Integration",
          "Search Engine Submission",
          "8 Stock Photos",
          "5 Unique Banner Design",
          "1 jQuery Slider Banner",
          "Complete W3C Certified HTML",
          "Facebook Page Design",
          "Twitter Page Design",
          "YouTube Page Design",
          "Complete Deployment",
          "100% Satisfaction Guarantee",
          "100% Unique Design Guarantee",
          "100% Money Back Guarantee *",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Silver Website",
        pricing: currency +"1595",
        cutPrice: currency +"3190",
        features: [
          "15 to 20 Pages Website",
          "Custom Made, Interactive, Dynamic & High End Design",
          "Custom WP (or) Custom PHP Development",
          "1 jQuery Slider Banner",
          "Up to 10 Custom Made Banner Designs",
          "10 Stock Images",
          "Unlimited Revisions",
          "Special Hoover Effects",
          "Content Management System (CMS)",
          "Online Appointment/Scheduling/Online Ordering Integration (Optional)",
          "Online Payment Integration (Optional)",
          "Multi Lingual (Optional)",
          "Custom Dynamic Forms (Optional)",
          "Signup Area (For Newsletters, Offers etc.)",
          "Search Bar",
          "Live Feeds of Social Networks integration (Optional)",
          "Mobile Responsive",
          "Free Google Friendly Sitemap",
          "Search Engine Submission",
          "Complete W3C Certified HTML",
          "Industry Specified Team of Expert Designers and Developers",
          "Complete Deployment",
          "Dedicated Accounts Manager",
          "Facebook Page Design",
          "Twitter Page Design",
          "YouTube Page Design",
          "100% Ownership Rights",
          "100% Satisfaction Guarantee",
          "100% Unique Design Guarantee",
          "100% Money Back Guarantee *",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Business Website",
        pricing: currency +"2495",
        cutPrice: currency +"4990",
        features: [
          "15 to 20 Pages Website",
          "Custom Made, Interactive, Dynamic & High End Design",
          "Custom WP (or) Custom PHP Development",
          "1 jQuery Slider Banner",
          "Up to 10 Custom Made Banner Designs",
          "10 Stock Images",
          "Unlimited Revisions",
          "Special Hoover Effects",
          "Content Management System (CMS)",
          "Online Appointment/Scheduling/Online Ordering Integration (Optional)",
          "Online Payment Integration (Optional)",
          "Multi Lingual (Optional)",
          "Custom Dynamic Forms (Optional)",
          "Signup Area (For Newsletters, Offers etc.)",
          "Search Bar",
          "Live Feeds of Social Networks integration (Optional)",
          "Mobile Responsive",
          "15 Seconds 2D Explainer Video",
          "Voice - Over & Sound Effects",
          "Professional Script Writing",
          "Storyboard",
          "SEO Meta Tags",
          "Free Google Friendly Sitemap",
          "Search Engine Submission",
          "Complete W3C Certified HTML",
          "Industry Specified Team of Expert Designers and Developers",
          "Complete Deployment",
          "Dedicated Accounts Manager",
          "100% Ownership Rights",
          "100% Satisfaction Guarantee",
          "100% Unique Design Guarantee",
          "100% Money Back Guarantee *",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Automated/ Interactive Conferencing Portal",
        pricing: currency +"4995",
        cutPrice: currency +"9990",
        features: [
          "Unlimited Page Website",
          "Custom Content Management System (CMS)",
          "Unique Pages and UI Design",
          "Complete Custom Development",
          "Process Automation Tools",
          "Newsfeed Integration",
          "Social Media Plugins Integration",
          "Up to 40 Stock Images",
          "10 Unique Banner Designs",
          "JQuery Slider",
          "Search Engine Submission",
          "Free Google Friendly Sitemap",
          "Custom Email Addresses",
          "Social Media Page Designs (Facebook, Twitter, Instagram)",
          "Complete W3C Certified HTML",
          "Complete Deployment",
          "100% Satisfaction Guarantee",
          "100% Unique Design Guarantee",
          "Money Back Guarantee",
          "Key features",
          "Automated Course Creation",
          "Video Conferencing",
          "Skills/Certification Tracking",
          "Mobile Learning",
          "Asynchronous Learning",
          "CRM Features",
          "Gamification",
          "Social Learning/Message Boards",
          "Motivational Triggers",
          "Forums And Webinars",
          "E-commerce And Subscriptions",
          "Online Course Booking",
          "Excellent Reporting",
          "Invoicing Integration",
          "Financial Integrations",
          "Student Information management",
          "Automated communications",
          "Learning Management System",
          "Quick And Easy Course Scheduling",
          "Reporting And Data Analysis",
          "Assessment Management & Live Feedback",
          "Gradebooks",
          "Quick User Integration",
          "Easy Payment Methods",
          "Online Communities & Social Engagement",
          "Curation of Resources And Adding Own Resources",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
    ],
    Ecommerce: [
      {
        name: "E-Commerce Basic Package",
        pricing: currency +"499",
        cutPrice: currency +"999",
        features: [
          "E-Commerce Website Design and Development",
          "Theme Based Design",
          "2 Banner Designs",
          "Sliding Banner",
          "3 Stock Photos",
          "06 Revisions",
          "Hover Effects",
          "Up to 10 Products",
          "Up to 3 Categories",
          "Content/Inventory Management System",
          "Easy Product Search Bar",
          "Shopping Cart Integration",
          "Payment Module Integration",
          "Direct Checkout",
          "Sign up Checkout",
          "Tax calculator Integration",
          "Shipping calculator Integration",
          "Wishlist",
          "Complete W3C Validation",
          "Dedicated Team of Designers and Developers",
          "Complete Deployment",
          "Value Added Services",
          "Complete Source Files",
          "Dedicated Project Manager",
          "100% Satisfaction and Money-Back Guarantee",
          "NO MONTHLY OR ANY HIDDEN FEE",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "E-Commerce Startup Package",
        pricing: currency +"699",
        cutPrice: currency +"1399",
        features: [
          "E-Commerce Website Design and Development",
          "High-end Theme Based Design",
          "5 Banner Designs",
          "Sliding Banner",
          "5 Stock Photos",
          "12 Revisions",
          "Hover Effects",
          "Up to 25 Products",
          "Up to 5 Categories",
          "Content/Inventory Management System",
          "Easy Product Search Bar",
          "Shopping Cart Integration",
          "Payment Module Integration",
          "Direct Checkout",
          "Sign up Checkout",
          "Tax calculator Integration",
          "Shipping calculator Integration",
          "Wishlist",
          "Search Engine Submission",
          "Social Media Pages Integration",
          "Facebook 'Like' Plugin integration",
          "Sitemap",
          "Complete W3C Validation",
          "Dedicated Team of Designers and Developers",
          "Complete Deployment",
          "Value Added Services",
          "Complete Source Files",
          "Dedicated Project Manager",
          "100% Satisfaction and Money-Back Guarantee",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Beginners E-Commerce",
        pricing: currency +"995",
        cutPrice: currency +"1990",
        features: [
          "Up to 15 Unique Pages Website",
          "Conceptual and Dynamic Website",
          "Content Management System (CMS)",
          "Mobile Responsive",
          "Easy Product Search",
          "Product Reviews",
          "Up To 100 Products",
          "Up To 7 Categories",
          "Full Shopping Cart Integration",
          "Payment Module Integration",
          "Sales & Inventory Management",
          "Jquery Slider",
          "Free Google Friendly Sitemap",
          "Custom Email Addresses",
          "Complete W3C Certified HTML",
          "Facebook Page Design",
          "Twitter Page Design",
          "YouTube Page Design",
          "Complete Deployment",
          "Dedicated Accounts Manager",
          "100% Ownership Rights",
          "100% Satisfaction Guarantee",
          "100% Unique Design Guarantee",
          "100% Money Back Guarantee",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Corporate E-Commerce",
        pricing: currency +"1795",
        cutPrice: currency +"3590",
        features: [
          "UNLIMITED Logo Design Concepts",
          "By 6 Award Winning Designers",
          "Icon Design",
          "UNLIMITED Revisions",
          "Print Media",
          "Stationary Design (BusinessCard,Letterhead & Envelope)",
          "Invoice Design, Email Signature",
          "Bi-Fold Brochure (OR) 2 Sided Flyer Design",
          "Product Catalog Design",
          "Signage Design (OR) Label Design",
          "T-Shirt Design (OR) Car Wrap Design",
          "Website",
          "E-Commerce Store Design",
          "Product Detail Page Design",
          "Unique Banner Slider",
          "Featured Products Showcase",
          "Full Shopping Cart Integration",
          "Unlimited Products",
          "Unlimited Categories",
          "Product Rating & Reviews",
          "Easy Product Search",
          "Payment Gateway Integration",
          "Multi-currency Support",
          "Content Management System",
          "Customer Log-in Area",
          "Mobile Responsive",
          "Social Media Plugins Integration",
          "Tell a Friend Feature",
          "Social Media Pages",
          "Facebook, Twitter, YouTube, Google+ & Pinterest Page Designs",
          "Value Added Services",
          "Dedicated Account Manager",
          "Unlimited Revisions",
          "All Final File Formats",
          "100% Ownership Rights",
          "100% Satisfaction Guarantee",
          "100% Unique Design Guarantee",
          "100% Money Back Guarantee *",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Elite E-Commerce",
        pricing: currency +"3695",
        cutPrice: currency +"7390",
        features: [
          "UNLIMITED Logo Design Concepts",
          "By 6 Award Winning Designers",
          "Icon Design",
          "UNLIMITED Revisions",
          "Print Media",
          "Stationary Design (Business Card, Letterhead & Envelope)",
          "Invoice Design, Email Signature",
          "Bi-Fold Brochure (OR) 2 Sided Flyer Design",
          "Product Catalog Design",
          "Signage Design (OR) Label Design",
          "T-Shirt Design (OR) Car Wrap Design",
          "Website",
          "E-Commerce Store Design",
          "Product Detail Page Design",
          "Unique Banner Slider",
          "Featured Products Showcase",
          "Full Shopping Cart Integration",
          "Unlimited Products",
          "Unlimited Categories",
          "Product Rating & Reviews",
          "Easy Product Search",
          "Payment Gateway Integration",
          "Multi-currency Support",
          "Content Management System",
          "Customer Log-in Area",
          "Mobile Responsive",
          "Social Media Plugins Integration",
          "Tell a Friend Feature",
          "Social Media Pages (Facebook, Twitter, YouTube, Google+ & Pinterest)",
          "Value Added Services",
          "Dedicated Account Manager",
          "Unlimited Revisions",
          "All Final File Formats",
          "100% Ownership Rights",
          "100% Satisfaction Guarantee",
          "100% Unique Design Guarantee",
          "100% Money Back Guarantee",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Automated  E-Commerce",
        pricing: currency +"7995",
        cutPrice: currency +"5,990",
        features: [
          "Unlimited Page Website",
          "Custom Content Management System (CMS)",
          "Unique Pages and UI Design",
          "Complete Custom Development",
          "Process Automation Tools",
          "Newsfeed Integration",
          "Social Media Plugins Integration",
          "Up to 40 Stock Images",
          "10 Unique Banner Designs",
          "JQuery Slider",
          "Search Engine Submission",
          "Free Google Friendly Sitemap",
          "Custom Email Addresses",
          "Social Media Page Designs (Facebook, Twitter, Instagram)",
          "Complete W3C Certified HTML",
          "Complete Deployment",
          "100% Satisfaction Guarantee",
          "100% Unique Design Guarantee",
          "Money Back Guarantee",
          "Automated Inventory/Shipping/Supplier Module:",
          "Manage thousands to millions of inventory with ease and check stock levels in real-time. Receive low inventory notifications and generate purchase orders to replenish your stock.",
          "Suppliers Integration (API NEEDED)",
          "Shipper Integration (API NEEDED)",
          "Order management",
          "LOT numbers and expire date tracking",
          "Transfer stock between warehouses (If Warehouse - API NEEDED)",
          "Receive stock into a specific warehouse (If Warehouse - API NEEDED)",
          "Fulfill orders from a particular warehouse (If Warehouse - API NEEDED)",
          "Stock Management",
          "Actionable Insights",
          "Real-Time Visibility",
          "Inventory Opportunities",
          "Advanced Features: (API Needed For Suppliers/Warehouse)",
          "Speak to suppliers during trivial conversations.",
          "Set and send actions to suppliers regarding governance and compliance materials. Place purchasing requests.",
          "Research and answer internal questions regarding procurement functionalities or a supplier/supplier set.",
          "Receiving/filing/documentation of invoices and payments/order requests",
          "Machine Learning (ML) for Supply Chain Planning (SCP)",
          "Machine Learning for Warehouse Management",
          "Natural Language Processing (NLP) for Data Cleansing and Building Data Robustness",
          "Automated Invoices & Estimates",
          "Create beautiful, professional invoices & estimates in just a few seconds and then instantly email them as PDF's directly to your customers or prospects.",
          "Automated Split invoicing",
          "Automated Combine invoices",
          "Invoice templates",
          "Automated Barcode Scanning",
          "Scan inventory into your orders, generate barcodes for your documents, and search for inventory or documents by scanning barcodes.",
          "Locations and Zones",
          "Have multiple warehouses, offices, or retail stores? No problem. Easily track where all your inventory is by organizing everything into locations and zones. Organize inventory items using custom attributes such as size, color, and location. View how many you have globally or at each location.",
          "Customer Accounts",
          "Performance and analytics",
          "Customization of Personal Details",
          "Process management",
          "Sales Automation",
          "Team Collaboration",
          "Marketing Automation",
          "Security",
          "Integrations",
          "Mobile Notifications",
          "Sales Reports",
          "Trend Analytics",
          "Forecasting",
          "Territory Management",
          "Account Management",
          "Event Integration",
          "Advanced Data Security",
          "Purchase Orders",
          "With integrated purchase orders, you can easily replenish your inventory levels by ordering more stock and even track when those new items will arrive.",
          "Partial orders fulfill",
          "Backordering",
          "Financial Reports",
          "Generate extremely detailed reports for your inventory, sales and services. Filter your reports by date-range and category to see what's making you the most money.",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
    ],
    Animation: [
      {
        name: "Teaser/Intro Video",
        pricing: currency +"145",
        cutPrice: currency +"290 ",
        features: [
          "10 Seconds Video",
          "Professional Script",
          "Sample Theme",
          "Storyboard",
          "Custom Artwork",
          "Animation",
          "Professional Voice-Over & Sound Effects",
          "1 week Delivery",
          "Unlimited Revisions",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Startup Video",
        pricing: currency +"195 ",
        cutPrice: currency +"390",
        features: [
          "30s Duration - HD 1080",
          "Professional Script",
          "Storyboard",
          "Sample Themes",
          "Custom Setting, Characters & Graphics",
          "Animation Effects & Visualization",
          "Voice-Over & Sound Effects (All accents) (M/F)",
          "Unlimited Revisions",
          "ADD ON: " + currency + "500 for 24 hours rush delivery",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Classic Video",
        pricing: currency +"395 ",
        cutPrice: currency +"790",
        features: [
          "30s Duration - HD 1080",
          "Professional Script",
          "Storyboard",
          "Sample Themes",
          "Custom Setting, Characters & Graphics",
          "Animation Effects & Visualization",
          "Voice-Over & Sound Effects (All accents) (M/F)",
          "Unlimited Revisions",
          "ADD ON: " + currency + "500 for 24 hours rush delivery",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Premium Video",
        pricing: currency +"695 ",
        cutPrice: currency +"1390",
        features: [
          "90 Second Video - HD 1080",
          "Professional Script",
          "Sample Theme",
          "Storyboard",
          "Animation",
          "Voice-Over & Sound Effects",
          "6 Weeks Delivery",
          "Unlimited Revisions",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Deluxe Video",
        pricing: currency +"895",
        cutPrice: currency +"1790",
        features: [
          "120 Second Video - HD 1080",
          "Professional Script",
          "Sample Theme",
          "Storyboard",
          "Animation",
          "Voice-Over & Sound Effects",
          "Unlimited Revisions",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
    ],
    SEO: [
      {
        name: "Startup SEO",
        pricing: currency +"445",
        cutPrice: currency +"890",
        features: [
          "5 Keywords",
          "Guaranteed Ranking on Google",
          "Off-site Optimization",
          "Link Building",
          "Social Bookmarking",
          "Basic Analytical Report",
          "In-depth Site Analysis",
          "Content Duplicity Check",
          "Initial Backlinks analysis",
          "Google Penalty Check",
          "Mobile Usability Check",
          "Competition Analysis",
          "Keyword Research",
          "Informational Content Writing & Sharing (2 - Per Month)",
          "Blog Writing (1 - Per Month)",
          "Press Release Social Bookmarking (5 - Per Month)",
          "NAP Syndication",
          "Google My Business / Bing Local Listing",
          "Citation Building",
          "Classified Submissions",
          "Google Analytics Analysis Report",
          "SEO Reports",
          "Search Engine Rank Report",
          "Dedicated Accounts Manager",
          "Monthly Action Plan",
          "Activity Report",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Identity SEO",
        pricing: currency +"845 ",
        cutPrice: currency +"1690",
        features: [
          "10 Keywords",
          "Guaranteed Ranking on Google",
          "Off-site Optimization",
          "On-site Optimization",
          "Link Building",
          "Social Bookmarking",
          "In-depth Site Analysis",
          "Content Duplicacy Check",
          "Initial Backlinks Analysis",
          "Google Penalty Check",
          "Mobile Usability Check",
          "Competition Analysis",
          "Keyword Research",
          "Title & Meta Tags Optimization",
          "Content Optimization",
          "Page Speed Analysis & Optimization",
          "HTML Code Cleanup & Optimization",
          "Internal Link Structuring & Optimization",
          "Pages H tags Optimization",
          "Canonicalization/301 Redirect",
          "Website Page Load Optimization",
          "Schema Markup Implementation",
          "Image & Hyperlink Optimization",
          "Robots.txt Creation/Analysis",
          "Blog Writing (2 - Per Month)",
          "Informational Content Writing & Sharing (1 Per Month)",
          "Press Release Writing & Distribution",
          "Press Release Social Bookmarking",
          "Google Webmaster Tools Setup",
          "Google Analytics Setup & Integration",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Elite SEO",
        pricing: currency +"1545",
        cutPrice: currency +"3090",
        features: [
          "20 Keywords",
          "Guaranteed Ranking on Google",
          "Off-site Optimization",
          "On-site Optimization",
          "Link Building",
          "Social Bookmarking",
          "In-depth Site Analysis",
          "Content Duplicacy Check",
          "Initial Backlinks Analysis",
          "Google Penalty Check",
          "Mobile Usability Check",
          "Competition Analysis",
          "Keyword Research",
          "Page Speed Analysis & Optimization",
          "Title & Meta Tags Optimization",
          "Content Optimization",
          "HTML Code Cleanup & Optimization",
          "Internal Link Structuring & Optimization",
          "Pages H tags Optimization",
          "Canonicalization/301 Redirect",
          "Website Page Load Optimization",
          "Robots.txt Creation/Analysis",
          "Press Release Writing & Distribution",
          "Press Release Social Bookmarking",
          "Schema Markup Implementation",
          "Image & Hyperlink Optimization",
          "Google Webmaster Tools Setup",
          "Google Analytics Setup & Integration",
          "Blog Writing (2 - Per Month)",
          "Informational Content Writing & Sharing (1 Per Month)",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Professional SEO",
        pricing: currency +"2145",
        cutPrice: currency +"4290",
        features: [
          "30 Keywords",
          "Guaranteed Ranking on Google",
          "Off-site Optimization",
          "On-site Optimization",
          "Link Building",
          "Social Bookmarking",
          "In-depth Site Analysis",
          "Content Duplicacy Check",
          "Initial Backlinks Analysis",
          "Google Penalty Check",
          "Mobile Usability Check",
          "Competition Analysis",
          "Keyword Research",
          "Page Speed Analysis & Optimization",
          "Title & Meta Tags Optimization",
          "Content Optimization",
          "HTML Code Cleanup & Optimization",
          "Internal Link Structuring & Optimization",
          "Pages H tags Optimization",
          "Canonicalization/301 Redirect",
          "Website Page Load Optimization",
          "Robots.txt Creation/Analysis",
          "Press Release Writing & Distribution",
          "Press Release Social Bookmarking",
          "Schema Markup Implementation",
          "Image & Hyperlink Optimization",
          "Google Webmaster Tools Setup",
          "Google Analytics Setup & Integration",
          "Blog Writing (2 - Per Month)",
          "Informational Content Writing & Sharing (1 Per Month)",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
    ],
    Combo: [
      {
        name: "Basic Combo",
        pricing: currency +"445",
        cutPrice: currency +"890",
        features: [
          "5 Custom Logo Design Concepts",
          "By 2 Designers",
          "Icon Design",
          "Business Card, Letterhead, Envelope, Fax Template",
          "MS Word Letterhead",
          "5 Page Website",
          "Mobile Responsive",
          "Team of Expert Designers & Developers",
          "8 Stock Images",
          "5 Banner Designs",
          "jQuery Sliders",
          "Free Google Friendly Sitemap",
          "Complete W3C Certified HTML",
          "Complete Deployment",
          "Facebook Page Design",
          "Twitter Page Design",
          "YouTube Page Design",
          "All Final File Formats",
          "Dedicated Account Manager",
          "100% Ownership Rights",
          "100% Satisfaction Guarantee",
          "100% Unique Design Guarantee",
          "100% Money Back Guarantee",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Startup Combo",
        pricing: currency +"995",
        cutPrice: currency +"1990",
        features: [
          "Unlimited Logo Design Concepts",
          "Social Media Design",
          "Mobile Responsive",
          "3 Dedicated Designers",
          "Icon Design",
          "Business Card, Letterhead, Envelope",
          "MS Word Letterhead",
          "UNLIMITED Pages Website",
          "Content Management System (CMS)",
          "5 Stock Photos + 3 Banner Designs",
          "Complete W3C Certified HTML",
          "Complete Deployment",
          "Facebook Page Design",
          "Twitter Page Design",
          "YouTube Page Design",
          "All Final File Formats",
          "Dedicated Account Manager",
          "100% Ownership Rights",
          "100% Satisfaction Guarantee",
          "100% Unique Design Guarantee",
          "100% Money Back Guarantee",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Professional Combo",
        pricing: currency +"1395 ",
        cutPrice: currency +"2790",
        features: [
          "Unlimited Logo Concepts",
          "8 Dedicated Designers",
          "Icon Design",
          "2 Free Custom Stationary Designs",
          "MS Word Letterhead",
          "Trifold Brochure Design",
          "Presentation Folder Design",
          "Conceptual and Dynamic Liquid Website",
          "Team of Expert Designers & Developers",
          "Mobile Responsive",
          "Online Reservation/Appointment Tool (Optional)",
          "Custom Forms",
          "Lead Capturing Forms (Optional)",
          "Newsfeed Integration",
          "Social Media Integration",
          "Search Engine Submission",
          "15 Stock Images",
          "8 Unique Banner Designs",
          "jQuery Sliders",
          "Free Google Friendly Sitemap",
          "Complete W3C Certified HTML",
          "Facebook Page Design",
          "Twitter Page Design",
          "YouTube Page Design",
          "Google+ Page Design",
          "All Final File Formats",
          "Dedicated Account Manager",
          "100% Ownership Rights",
          "100% Satisfaction Guarantee",
          "100% Unique Design Guarantee",
          "100% Money Back Guarantee",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Corporate Combo",
        pricing: currency +"1995 ",
        cutPrice: currency +"3990",
        features: [
          "8 Dedicated Designers",
          "Icon Design",
          "2 Free Custom Stationary Designs",
          "MS Word Letterhead",
          "Invoice Design",
          "Product Catalog Design",
          "Unlimited Pages Website",
          "Conceptual and Dynamic Website",
          "Content Management System (CMS)",
          "Easy Product Search",
          "Product Reviews",
          "Unlimited Products",
          "Unlimited Categories",
          "Promotional Product Showcase",
          "New Product Showcase",
          "Full Shopping Cart Integration",
          "Payment Module Integration",
          "Sales & Inventory Management",
          "Custom Forms",
          "Lead Capturing Forms (Optional)",
          "Newsfeed Integration",
          "Social Media Integration",
          "Search Engine Submission",
          "Team of Dedicated Designers, Developers and Brand Experts",
          "20 Stock Images",
          "6 Unique Banner Designs",
          "jQuery Slider",
          "Free Google Friendly Sitemap",
          "Complete W3C Certified HTML",
          "Facebook Page Design",
          "Twitter Page Design",
          "YouTube Page Design",
          "Google+ Page Design",
          "Pinterest Page Design",
          "All Final File Formats",
          "Dedicated Account Manager",
          "100% Ownership Rights",
          "100% Satisfaction Guarantee",
          "100% Unique Design Guarantee",
          "100% Money Back Guarantee",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Elite Combo",
        pricing: currency +"2995 ",
        cutPrice: currency +"5990",
        features: [
          "Complete Custom Design & Development",
          "Client/User Dashboard Area",
          "Custom Coding",
          "Custom PHP Development",
          "Content Management System (CMS)",
          "Online Appointment/Scheduling/Online Ordering Integration (Optional)",
          "Online Payment Integration (Optional)",
          "Multi Lingual (Optional)",
          "Custom Dynamic Forms (Optional)",
          "Signup Area (For Newsletters, Offers etc.)",
          "Search Bar",
          "Live Feeds of Social Networks integration (Optional)",
          "Mobile Responsive Web",
          "Search Engine Submission",
          "Module-wise Architecture",
          "Extensive Admin Panel",
          "Award Winning Team of Expert Designers and Developers",
          "Complete Deployment",
          "Facebook Page Design",
          "Twitter Page Design",
          "YouTube Page Design",
          "100% Custom Designs - No Templates",
          "100% Satisfaction Guarantee",
          "100% Unique Design Guarantee",
          "100% Money Back Guarantee",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
    ],
    SocialMedia: [
      {
        name: "Basic Social Media",
        pricing: currency +"350",
        cutPrice: currency +"890",
        features: [
          "3 Postings Per Week (Per Network) Facebook + Twitter + Instagram + Google+",
          "Content Creation",
          "Business Page Optimization",
          "Social Media Strategy (Overview)",
          "Facebook Likes Campaign",
          "Monthly Progress Report",
          "Copy Writing",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Startup Social Media",
        pricing: currency +"750.00",
        cutPrice: currency +"1990",
        features: [
          "Copywriting & Visual Designs",
          "Business Page Optimization",
          "Ad Campaign Management",
          "Spam Monitoring",
          "Monthly Progress Report",
          "5 Postings Per Week Facebook + Twitter + Instagram + Google+",
          "Reputation Management",
          "Social Account Setup",
          "Content Creation",
          "Social Media Listening",
          "Query And Comments Reply",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
      {
        name: "Professional Social Mdia",
        pricing: currency +"1,250 ",
        cutPrice: currency +"2790",
        features: [
          "Copywriting & Visual Designs",
          "Business Page Optimization",
          "Ad Campaign Management",
          "Spam Monitoring",
          "6 Postings Per Week Facebook + Twitter + Instagram + Google+",
          "Reputation Management",
          "Social Account Setup",
          "Content Creation",
          "Social Media Hearing",
          "Query And Comments Reply",
        ],
        description:
          "Suitable for potential super-startups and brand revamps for companies.",
      },
    ],
  }

  
  // chicken lion cat
  return (
    <div className="pricing__section__main">
      <img
        className="sketh__images"
        src={sketchBottom}
        alt="sketch top"
      />
      <div className="about__sub__section">
        {homepg && (

            <div className="d-flex flex-column align-items-center gap-4 text-center mb-4">
            <HeadingWithTag
              black
              text="Choose Your Plan"
            />
            <p className="portfolio__description text-white">
              At Apexverse, we get how important a great web design is for your
              online success. Check out our pricing options, designed to fit
              different needs and budgets, while keeping things competitive.
            </p>
            </div>
        )}
        <div className="tab__section">
          {tabs?.map((v, i) => {
            return (
              <CTAButton
                onClick={() => changeTab(i)}
                text={v}
                key={i}
                customClass={`padd__less ${activeTab === i ? "active" : ""} `}
              />
            )
          })}
        </div>
        <div className="container">
          <div className="row pricing__card__container">
            {cards?.[tabs[activeTab]?.replaceAll(" ", "")]?.map((v, i) => {
              return (
                <div
                  className={"pricing__card" + (homepg ? " small" : "")}
                  key={i}>
                  <h2>{v.name}</h2>
                  <h3>{v.pricing}</h3>
                  <p>{v?.description}</p>
                  <div className="features__list">
                    {v.features.map((feature, index) => {
                      return (
                        <div className="d-flex gap-2">
                          <BsCheckLg
                            color="#ffb300"
                            size={17}
                          />
                          <p key={index}>{feature}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <img
          className="sketh__images"
          src={sketchTop}
          alt="sketch top"
        />
      </div>
    </div>
  )
}

export default Index
