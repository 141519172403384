import React, { useContext } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import bgSktech from '../../../../assets/images/about__bg__sketch.png'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './styles.scss';

// import required modules
import { AuthContext } from '../../../../context';

export default function ReviewCarousel({ reviews }) {

  const { setCursor } = useContext(AuthContext)

  const mouseHover = () => setCursor("hidden")
  const mouseOut = () => setCursor("")

  return (
    <>
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        lazy={true}
        pagination={false}
        navigation={false}
        className="about__review__swipper"
        loop
      >
        {reviews?.map((v, i) => {
          return <SwiperSlide onMouseOver={mouseHover} onMouseOut={mouseOut} key={i}>
            <div onMouseOver={mouseHover} onMouseOut={mouseOut} className='carousel__card d-flex'>
              <div className='col-5 card__img__section'>
                <img alt='lion' className='main__img' src={v?.imgUrl} />
                <div className='sketch__section col-5'>
                  <img alt='sketch' src={bgSktech} />
                </div>
              </div>
              <div onMouseOver={mouseHover} onMouseOut={mouseOut} className='col-7 card__text__section'>
                <div>
                  <h3 className='name'>
                    {v?.name}
                  </h3>
                  <p className='designation'>
                    {v?.detail}
                  </p>
                  <p className='text'>
                    {v?.description}
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        })}
      </Swiper>
    </>
  );
}
