import React, { useEffect, useRef } from "react";
import "./style.scss";
import texture from "../../assets/images/PaintTexture.png";
import brush from "../../assets/images/brush.png";
import CTABtn from "../../components/buttons/CTABtn";
import arrowLeft from "../../assets/images/svg/arrowleft.svg";
import arrowright from "../../assets/images/svg/arrowright.svg";

const Index = () => {
  const compoRef = useRef(null);
  const [isScrolled, setIsScrolled] = React.useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const component = compoRef.current;
      const scrollPosition = window.scrollY + window.innerHeight;
      const componentPosition = component.offsetTop + component.offsetHeight;

      if (
        scrollPosition > componentPosition ||
        componentPosition - scrollPosition < 100
      ) {
        setIsScrolled(true);
      } else {
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div ref={compoRef} className="overflow__hid">
      <div className={`impress__main__2 ${isScrolled ? "tranl" : ""}`}>
        <img className="impress__img" src={texture} alt="texture" />
        <img className="impress__brush" src={brush} alt="brush" />
        <div className="impress__text__section container">
          <div className="impress__heading__section">
            <h2>
              <div>
                <span>READY-TO-</span>IMPRESS
              </div>
              <div className="mt-2 d-flex justify-content-between">
                <div>
                  <img
                    alt="arrow__left"
                    className="arrow__images"
                    src={arrowLeft}
                  />
                </div>
                <div>APP Designs</div>
                <div>
                  <img
                    alt="arrow__right"
                    className="arrow__images"
                    src={arrowright}
                  />
                </div>
              </div>
              <div className="mt-3">
                <span>And</span> Website Concepts
              </div>
            </h2>
            <div className="d-flex justify-content-center mt-3">
              <CTABtn bgPink text={"Request a Concept"} />
            </div>
          </div>
          <div className="w-30 right__text__section">
            <p className="right__text">
              We specialize in producing precise and attractive concepts for
              online and app design. It demonstrates our commitment to the
              functional quality and aesthetic attractiveness of your web
              platform.
              <br />
              Let our digital concepts serve as the baseline and template for
              your aspirations.
            </p>
          </div>
          <div className="w-30"></div>
        </div>
      </div>
    </div>
  );
};

export default Index;
