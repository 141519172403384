import "./style.scss"
import sketchBottom from "../../../assets/images/footer-bottom-texturev2.png"
import sketchTop from "../../../assets/images/footer-top-texturev2.png"

const Index = ({ policiesData }) => {
  //   const policiesData = [
  //     {
  //       policy: "1. Introduction",
  //       detail:
  //         "At Apexverse, we are committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, and protect your personal data when you use our software development, marketing, and design services. It also explains your rights under the UK General Data Protection Regulation (UK GDPR) and the Data Protection Act 2018. By using our website and services, you consent to the data practices described in this policy.",
  //     },

  //     {
  //       policy: "2. Information We Collect",
  //       detail:
  //         "We collect the following types of personal data from you:\nPersonal Identification Data: Name, email address, phone number, company details.\nFinancial Data: Payment details (processed by secure third-party providers).\nMarketing Preferences: Data related to your preferences for receiving marketing communications.\nTechnical Data: IP address, browser type, time zone setting, operating system, and platform.\nUsage Data: Information on how you use our website, products, and services.\nWe collect this information directly from you when you fill in forms, contact us, or use our services.",
  //     },

  //     {
  //       policy: "3. How We Use Your Data",
  //       detail:
  //         "We process your personal data for the following purposes:\nTo provide our software development, marketing, and design services.\nTo manage payments and invoicing.\nTo communicate with you regarding your inquiries, orders, or any services we provide.\nFor marketing purposes, such as sending promotional offers and updates (with your explicit consent).\nTo improve our website, products, and services by analyzing usage data.\nTo comply with our legal obligations, such as financial record-keeping.",
  //     },

  //     {
  //       policy: "4. Legal Basis for Processing",
  //       detail:
  //         "We process your personal data based on the following legal grounds:\nPerformance of a contract: To provide the services you requested.\nLegitimate interest: For improving services, marketing, and fraud prevention.\nConsent: For sending you marketing communications (where required).\nLegal compliance: To fulfill our legal obligations under UK laws.",
  //     },

  //     {
  //       policy: "5. Data Retention",
  //       detail:
  //         "We will only retain your personal data for as long as necessary to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting requirements. In general, personal data related to financial transactions will be retained for a minimum of six years, in compliance with UK tax laws.",
  //     },

  //     {
  //       policy: "6. Sharing Your Data",
  //       detail:
  //         "We will never sell your data. However, we may share your personal data with third parties for the following reasons:\nService providers: Such as payment processors, hosting services, and IT support providers.\nLegal compliance: If we are legally required to disclose your data to comply with any legal obligations, court orders, or government regulations.",
  //     },

  //     {
  //       policy: "7. International Data Transfers",
  //       detail:
  //         "As a UK-based business, your data will primarily be processed within the United Kingdom. If we transfer your data outside of the UK, we will ensure that appropriate safeguards are in place to protect your privacy, such as using standard contractual clauses approved by the UK GDPR.",
  //     },

  //     {
  //       policy: "8. Your Rights",
  //       detail:
  //         "Under the UK GDPR, you have the following rights concerning your personal data:\nRight to access: You can request access to your personal data we hold.\nRight to rectification: You can ask us to correct any inaccurate data about you.\nRight to erasure: You can request that we delete your personal data, subject to legal obligations.\nRight to restrict processing: You can ask us to suspend the processing of your data under certain circumstances.\nRight to data portability: You can request to receive your personal data in a structured format for transmission to another provider.\nRight to object: You can object to the processing of your data based on legitimate interests or direct marketing",
  //     },
  //   ]

  return (
    <div className="main">
      <img
        className="sketh__images"
        src={sketchBottom}
        alt="sketch top"
      />
      <div className="policies d-flex pt-3 flex-column align-items-center">
        {policiesData.map((policy) => {
          return (
            <>
              <div className="policy d-flex mt-3">
                <div className="policy_number d-flex flex-column col-5">
                  <h2 className="number">{policy.policy.split(".")[0]}</h2>
                  <h2 className="name">{policy.policy.split(".")[1]}</h2>
                </div>
                <div className="policy_details">
                  {policy.detail.split("\n").map((line) => {
                    return <p>{line}</p>
                  })}
                </div>
              </div>
            </>
          )
        })}
      </div>
      <img
        className="sketh__images bottom__sketch"
        src={sketchTop}
        alt="sketch top"
      />
    </div>
  )
}

export default Index
