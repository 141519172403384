import React, { useState } from "react";
import "./style.scss";
import { CTAButton, Lines } from "../../../components";
import bulOff from "../../../assets/images/contact/bulb-off.png";
import bulbOn from "../../../assets/images/contact/bulb-on.png";
import arrow from "../../../assets/images/contact/arrow.svg";
import { HiOutlineMail } from "react-icons/hi";

const Index = () => {
  const [bulb, setBulb] = useState(false);

  const hoverToImage = () => setBulb(true);
  const outToImage = () => setBulb(false);

  // chicken lion cat
  return (
    <div className="hero__contact__main">
      <div className="about__sub__section">
        <div className="flex__section container">
          <div className="images__area">
            <div onMouseOver={hoverToImage} onMouseOut={outToImage}>
              <img alt="bulb off" src={bulOff} />
              {bulb ? (
                <img alt="bulb on" className="bulb__on" src={bulbOn} />
              ) : (
                ""
              )}
              <div className="arrow__section">
                <img alt="arrow left" src={arrow} />
                <img alt="arrow right" className="right" src={arrow} />
              </div>
            </div>
          </div>
          <div className="about__area">
            <p>
              An idea lights up in your head. It astounds and inspires and
              reignites a creative spark that you once had. You make up your
              mind to create something new and unique that can even change the
              course of the future.
            </p>
            <p>Until…</p>
            <p>
              You realise it’s not easy. Your goals need clarity, your team
              needs assistance, and your efforts demand reinforcement.
            </p>
            <p>
              That is where Apexverse comes in. Bring your idea to the table and
              watch us bring pixel-perfect accuracy to your roadmap of success.
            </p>
            <div className="email__section">
              <a href="mailto:hello@apexverse.co">
                <HiOutlineMail size={20} /> &nbsp; hello@apexverse.co
              </a>
              <div className="mt-3">
                <CTAButton text={"Submit a brief"} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="line__section">
        <Lines fontSize={62} text={"The idea"} dir={"ltr"} side={"left"} />
        <Lines
          fontSize={62}
          layer={2}
          text={"The idea"}
          dir={"rtl"}
          side={"left"}
        />
      </div>
    </div>
  );
};

export default Index;
