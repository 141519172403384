import pic1 from './1.webp';
import pic2 from './14.webp';
import pic3 from './19.webp';
import pic4 from './20.webp';
import pic5 from './26.webp';
import pic6 from './3.webp';
import pic7 from './53.webp';
import pic8 from './54.webp';
import pic9 from './6.webp';
import pic10 from './7.webp';

export let ecomPictures = [
  pic1,
  pic2,
  pic3,
  pic4,
  pic5,
  pic6,
  pic7,
  pic8,
  pic9,
  pic10,
];
