import React, { useContext, useState } from "react";
import { Drawer } from "antd";
import { IoCloseSharp } from "react-icons/io5";
import footerTexure from "../../assets/images/footer__texture.png";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { AuthContext } from "../../context";
import heroicon from "../../assets/images/svg/heroIconWhite.svg";
import birdImage from "../../assets/images/birds.png";

const NavbarDrawer = ({ open, onClose }) => {
  const [size] = useState("large");

  const { setCursor } = useContext(AuthContext);

  const data = [
    {
      icon: <BsFillTelephoneFill className="info__icons" />,
      text: "0654515465",
    },
    {
      icon: <HiOutlineMail className="info__icons" />,
      text: "hello@apexverse.co",
    },
    {
      icon: <FaMapMarkerAlt className="info__icons" />,
      text: "513 Cheam Sutton London Road",
    },
    {
      icon: "",
      text: "© Apex verse - All Rights Reserved",
    },
  ];

  const menus = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "About US",
      link: "/about   ",
    },
    {
      name: "Packages",
      link: "/packages",
    },
    {
      name: "Services",
      link: "/services",
    },
    {
      name: "Portfolio",
      link: "/portfolio",
    },
    {
      name: "Contact us",
      link: "/contact",
    },
  ];

  const mouseOut = () => setCursor("");
  const mouseHover = () => setCursor("fill");
  const [showDetails, setShowDetails] = useState(false);

  const MenuBtn = ({ v }) => {
    const showServiceDetails = () => {
      setShowDetails((sh) => !sh);
    };

    const services = [
      {
        name: "Web Design",
        link: "webdesign",
      },
      {
        name: "Web Development",
        link: "webdevelopment",
      },
      {
        name: "Brand Marketing",
        link: "designbranding ",
      },
      {
        name: "Digital Marketing",
        link: "digitalmarketing",
      },
    ];

    console.log("Sghow detila", showDetails);

    return (
      <div>
        {v?.name === "Services" ? <button
          onMouseOver={mouseHover}
          onMouseOut={mouseOut}
          onClick={() => showServiceDetails()
          }
          className="menu__btn"
        >
          {v?.name}
        </button> :
          <a
            href={v?.link}
            className="menu__btn"
          >
            {v?.name}
          </a>
        }
        {showDetails && v?.name === "Services" ? (
          <div className="services__btns">
            {services?.slice(0, 2)?.map((e, i) => {
              return (
                <div key={i}>
                  <a
                    href={`${v?.link}/${e?.link}`}
                    className="menu__btn sml__btn"
                  >
                    {e?.name}
                  </a>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {showDetails && v?.name === "Services" ? (
          <div className="services__btns">
            {services?.slice(2, 4)?.map((e, i) => {
              return (
                <div key={i}>
                  <a
                    href={`${v?.link}/${e?.link}`}
                    className="menu__btn sml__btn"
                  >
                    {e?.name}
                  </a>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <>
      <Drawer
        placement="right"
        size={size}
        onClose={onClose}
        open={open}
        headerStyle={{ display: "none" }}
        width={"100%"}
        destroyOnClose={true}
        className="drawer__main"
      >
        <div className="w-100 d-flex justify-content-end drawer__close__btn">
          <button onClick={onClose} className="close__btn">
            <IoCloseSharp className="close__icon" />
          </button>
        </div>
        <div className="flex__section">
          <div className="menu__section">
            <div className="position-relative menu__btns__section">
              <img className="hero__icon" src={heroicon} alt="heroIcon" />
              {menus?.map((v, i) => {
                return <MenuBtn v={v} key={i} />;
              })}
            </div>
          </div>
          <div className="bird__img__section">
            <div className="position-relative">
              <div className="bird__box" />
              <img className="bird__img" src={birdImage} alt="birdImg" />
            </div>
          </div>
        </div>
        <div className="drawer__footer">
          <img src={footerTexure} alt="footerTexture" />
          <div className="d-flex justify-content-center align-items-center gap-3">
            {data?.map((v, i) => {
              return (
                <div key={i} className="d-flex gap-2 header__footer">
                  <div>{v?.icon}</div>
                  <div>{v?.text}</div>
                </div>
              );
            })}
          </div>
        </div>
      </Drawer>
    </>
  );
};
export default NavbarDrawer;
