import React from 'react';
import './style.scss';
import { Footer, MouseRing, Navbar } from '../../components';
import {  AboutHero, AboutTextSection, AboutTextSection2, AboutUsReview } from '../../layout';

const Index = () => {
    return (
        <div className='root'>
            <MouseRing />
            <Navbar />
            <AboutHero title={"ABOUT"}/>
            <AboutTextSection />
            <AboutUsReview />
            <AboutTextSection2 />
            <Footer />
            <div className='home__main'>
            </div>
        </div>
    )
}

export default Index