import React, { useState } from 'react'
import { createContext } from 'react'

export const AuthContext = createContext()

const AuthProvider = ({ children }) => {

    const [cursor, setCursor] = useState('') // hidden fill

    return (
        <AuthContext.Provider value={{
            setCursor,
            cursor
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
