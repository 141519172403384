import React from "react";
import "./style.scss";
import serverImage from "../../../assets/images/services/creative-machine.png";
import designerGirl from "../../../assets/images/services/designer-girl.png";
import SplashBulb from "../../../assets/images/services/SplashBulb.png";
import cloudImage from "../../../assets/images/services/cloud.png";
import CTABtn from "../../../components/buttons/CTABtn";
import { BsCheckLg } from "react-icons/bs";

const Index = () => {
  return (
    <div className="service__details__main container">
      <h2 data-aos="flip-right" className="service__detail__heading">
        INTRODUCING DIGITAL SUPREMACY
      </h2>
      <div className="row p-0 m-0 details__sub__container">
        <div className="halfWidth p-0 m-0 image__section position-relative pt-4">
          <div className="cloud__container rtl">
            <img alt="cloud" src={cloudImage} />
          </div>
          <div className="cloud__container ltr bot">
            <img alt="cloud" src={cloudImage} />
          </div>
          <img alt="server" data-aos="fade-right" src={serverImage} />
        </div>
        <div
          data-aos="fade-up"
          className="halfWidth p-0 m-0 text__section pt-4"
        >
          <p>
            If your brand is a hidden gem, it is our obligation to bring it to
            light. As a result, we use expert-approved strategies, tools, and
            techniques to establish ourselves as your reliable UK branding
            agency.
          </p>
          <p>
            Our staff goes above and beyond to enhance your digital presence
            with an engaging message, paint it with unique color schemes, and
            represent it online with an unforgettable logo design.
          </p>
          <p>
            All you have to do is sit back and let the spotlight shine on your
            brand.
          </p>
          <div className="mt-3">
            <CTABtn text={"ENQUIRE NOW"} />
          </div>
        </div>
      </div>
      <div className="row p-0 m-0 mt-4 details__sub__container">
        <div className="halfWidth p-0 m-0 text__section">
          <h2 data-aos="flip-right" className="position-relative">
            <div className="cloud__container rtl">
              <img alt="cloud" src={cloudImage} />
            </div>
            <div className="cloud__container ltr bot">
              <img alt="cloud" src={cloudImage} />
            </div>
            Accelerating your growth
          </h2>
          <p data-aos="fade-up">
            With our exceptional design and branding services, you can get ahead
            of the high-performing, top-ranking brands.
            <p data-aos="fade-up">
              All our digital design architects need is a green light to turn
              your company into the market's top powerhouse. Our branding agency
              United Kingdom then transfers its station from standby to turbo
              motion.{" "}
            </p>
            <p data-aos="fade-up">
              We fine-tune tried-and-true techniques and consistently improve
              your brand identity to position it as the undisputed victor of a
              competitive digital landscape.{" "}
            </p>
          </p>
          <div className="mt-3">
            <CTABtn text={"ENQUIRE NOW"} />
          </div>
        </div>
        <div
          data-aos="fade-right"
          className="halfWidth p-0 m-0 image__section bulb__section"
        >
          <img alt="bulb" src={SplashBulb} />
        </div>
      </div>
      <h2 data-aos="flip-right" className="mt-4 service__detail__heading">
        AFFECTIVE CLIMAX
      </h2>
      <div className="row p-0 m-0 mt-3 details__sub__container">
        <div className="halfWidth p-0 m-0 designerGirl image__section position-relative">
          <div className="cloud__container rtl">
            <img alt="cloud" src={cloudImage} />
          </div>
          <div className="cloud__container ltr bot">
            <img alt="cloud" src={cloudImage} />
          </div>
          <img
            alt="girl"
            data-aos="fade-right"
            className="girlImg"
            src={designerGirl}
          />
        </div>
        <div className="halfWidth p-0 m-0 text__section position-relative">
          <div data-aos="fade-up" className="cloud__container rtl">
            <img alt="cloud" src={cloudImage} />
          </div>
          <div className="cloud__container ltr bot">
            <img alt="cloud" src={cloudImage} />
          </div>
          <p>
            Your digital breakthrough is just waiting to happen. Are you ready
            to go on the journey that our branding agency has exclusively
            planned?
          </p>
          <p>Join us for a smooth voyage into a future that promises</p>

          <div className="list__item">
            <BsCheckLg color="#ffb300" size={17} /> Increased brand awareness
          </div>
          <div className="list__item">
            <BsCheckLg color="#ffb300" size={17} /> Bespoke visual identity
          </div>
          <div className="list__item">
            <BsCheckLg color="#ffb300" size={17} /> Improved credibility
          </div>
          <div className="mt-4">
            <CTABtn text={"ENQUIRE NOW"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
