import React from "react";
import "./style.scss";
import serverImage from "../../../assets/images/services/rocketImg.png";
import designerGirl from "../../../assets/images/services/current.png";
import SplashBulb from "../../../assets/images/services/SplashBulb.png";
import cloudImage from "../../../assets/images/services/cloud.png";
import CTABtn from "../../../components/buttons/CTABtn";
import { BsCheckLg } from "react-icons/bs";

const Index = () => {
  return (
    <div className="service__details__main container">
      <h2 data-aos="flip-right" className="service__detail__heading">
        SUPREME SEO SERVICES
      </h2>
      <div className="row p-0 m-0 details__sub__container">
        <div className="halfWidth p-0 m-0 image__section position-relative pt-4">
          <div className="cloud__container rtl">
            <img alt="cloud" src={cloudImage} />
          </div>
          <div className="cloud__container ltr bot">
            <img alt="cloud" src={cloudImage} />
          </div>
          <img alt="server" data-aos="fade-right" src={serverImage} />
        </div>
        <div
          data-aos="fade-up"
          className="halfWidth p-0 m-0 text__section pt-4"
        >
          <p>
            Our one-stop digital marketing store offers SEO solutions that are
            data-driven, lead-capturing, and conversion-generating.
            <p>
              We are not your typical SEO service providers who will settle for
              anything less than your brand's unprecedented success. Indeed, our
              staff goes above and above to create valuable backlinks that
              increase organic traffic and reputation to your website.
            </p>
            <p>
              As a result, Apexverse is prepared to go to any length to provide
              you with the SEO services you require in the world..{" "}
            </p>
          </p>
          <div className="mt-3">
            <CTABtn text={"ENQUIRE NOW"} />
          </div>
        </div>
      </div>
      <div className="row p-0 m-0 mt-4 details__sub__container">
        <div className="halfWidth p-0 m-0 text__section">
          <h2 data-aos="flip-right" className="position-relative">
            <div className="cloud__container rtl">
              <img alt="cloud" src={cloudImage} />
            </div>
            <div className="cloud__container ltr bot">
              <img alt="cloud" src={cloudImage} />
            </div>
            ROI-BOOSTING PPC SERVICES
          </h2>
          <p data-aos="fade-up">
            Looking for full-service, next-generation, future-proof digital
            marketing solutions to reach your desired audience?
          </p>
          <p data-aos="fade-up">
            Our PPC agency is prepared to create an optimal PPC campaign for
            your brand's digital success. We continue to revolutionize PPC
            services in world and create new benchmarks by using the power of
            different marketing channels.
          </p>
          <p data-aos="fade-up">
            As a result, Apexverse can surely provide more than what you think
            when searching for the best PPC agency in the world.
          </p>
          <div data-aos="fade-up" className="mt-3">
            <CTABtn text={"ENQUIRE NOW"} />
          </div>
        </div>
        <div className="halfWidth p-0 m-0 image__section bulb__section">
          <img alt="bulb" data-aos="fade-left" src={SplashBulb} />
        </div>
      </div>
      <h2 data-aos="flip-right" className="mt-4 service__detail__heading">
        SOCIAL MEDIA MARKETING
      </h2>
      <div className="row p-0 m-0 mt-3 details__sub__container">
        <div className="halfWidth p-0 m-0 designerGirl image__section position-relative">
          <div className="cloud__container rtl">
            <img alt="cloud" src={cloudImage} />
          </div>
          <div className="cloud__container ltr bot">
            <img alt="cloud" src={cloudImage} />
          </div>
          <img data-aos="fade-right" className="girlImg" src={designerGirl} />
        </div>
        <div
          data-aos="fade-up"
          className="halfWidth p-0 m-0 text__section position-relative"
        >
          <div className="cloud__container rtl">
            <img alt="cloud" src={cloudImage} />
          </div>
          <div className="cloud__container ltr bot">
            <img alt="cloud" src={cloudImage} />
          </div>
          <p>Are you prepared to amaze your audience? </p>
          <p>
            Create your own identity and establish a presence on key social
            media platforms such as Instagram, X, and Facebook. Our social media
            marketing business utilizes the most recent trends and experiments
            with novel and interesting SMM tactics to:{" "}
          </p>
          <div className="list__item">
            <BsCheckLg color="#ffb300" size={17} />
            Increase your brand's reach.
          </div>
          <div className="list__item">
            <BsCheckLg color="#ffb300" size={17} />
            Improve customer engagement
          </div>
          <div className="list__item">
            <BsCheckLg color="#ffb300" size={17} />. Increase your web presence
          </div>
          <p>
            Prepare to lift off to the land of online prosperity as we continue
            our breakthrough in redefining "social media marketing".
          </p>{" "}
          <div className="mt-4">
            <CTABtn text={"ENQUIRE NOW"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
