import { FloatButton } from 'antd'
import React from 'react'
import { BsWhatsapp } from 'react-icons/bs'

function WhatsappBtn() {

    const whatsappLink = 'https://wa.me/+447301494637?text=Hello%20I%20am%20interested%20in%20your%20services'

    return (
        <FloatButton
            shape="circle"
            style={{ right: 24 }}
            icon={<BsWhatsapp color='rgb(201, 49, 114)' />}
            href={whatsappLink}
            target='_blank'
        />
    )
}

export default WhatsappBtn