import React, { useState } from 'react';
import './style.scss';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import sketchTop from '../../../assets/images/footer-top-texturev2.png';
import sketchBottom from '../../../assets/images/footer-bottom-texturev2.png';
import { ecomPictures } from '../../../assets/images/portfolio/e-commerce Portfolio/ecomPortfolio';
import { illusPictures } from '../../../assets/images/portfolio/Illustrations/illustrationsPortfolio';
import { logoPictures } from '../../../assets/images/portfolio/Logo Design/logoDesignPortfolio';
import { mobilePictures } from '../../../assets/images/portfolio/Mobile Portfolio Images/mobilePortfolio';
import { websitePictures } from '../../../assets/images/portfolio/Website Portfolio/websitePortfolio';

const Index = ({ setFullImage }) => {
  Fancybox.bind('[data-fancybox]', {});

  const categories = {
    'E-Store': ecomPictures,
    'Web Design': websitePictures,
    'Mobile Apps': mobilePictures,
    Branding: logoPictures,
    Illustrations: illusPictures,
  };

  const [selectedCategory, setSelectedCategory] = useState('E-Store');

  const PortfolioCard = ({ v }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <div
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        className={`mt-3 col-md-4`}
        data-src={v}
        data-fancybox="gallery"
      >
        <div className={`portfolio__card__main w-100 position-relative`}>
          {isHovered ? (
            <div
              // onClick={() => setFullImage(v?.imageUrl)}
              className="hoverd__div"
            ></div>
          ) : null}
          <img
            className="w-100"
            src={v}
            data-aos="zoom-in"
            alt="portfolio"
          />
        </div>
      </div>
    );
  };

  // chicken lion cat
  return (
    <>
      <div className="portfolio__card__section__main">
        <img
          className="sketh__images"
          src={sketchBottom}
          alt="sketch top"
        />
        <div className="about__sub__section">
          <div className="container">
            {Object.keys(categories)?.map((name, i) => {
              return (
                <span
                  className="bracket"
                  onClick={() => {
                    console.log('Button clicked');
                    setSelectedCategory(name);
                  }}
                >
                  {'{'}
                  <a
                    className="category_btn sml__btn"
                    key={i}
                    style={{
                      textDecoration:
                        selectedCategory === name ? 'line-through 4px red' : '',
                    }}
                  >
                    {name}
                  </a>
                  {'}'}
                </span>
              );
            })}

            <div className="row portfolio__card__section">
              {categories[selectedCategory]?.map((v, i) => {
                return (
                  <PortfolioCard
                    v={v}
                    key={i}
                  />
                );
              })}
            </div>
          </div>

          <img
            className="sketh__images bottom__sketch"
            src={sketchTop}
            alt="sketch top"
          />
        </div>
      </div>
    </>
  );
};

export default Index;
